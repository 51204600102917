import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import NotFound from "../../../components/NotFound/NotFound";
const MunicipalRoleList = () => {
  const headers = ["Name", "Description"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchMunicipalRoles = async () => {
    setIsLoading(true);
    try {
      const roles = await adminRequest.get(`${BASE_URL}/municipalRole/get`);
      const fetchedRows = roles?.data.data.map((r) => [r.name, r.description]);
      setRows(fetchedRows);
    } catch (error) {
      <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchMunicipalRoles();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  updateAuthToken();

  const getMenuItems = (row) => [
    {
      link: `/edit/${row[0]}`,
      text: "Edit",
    },
  ];

  return (
    <div className="listContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <List
          title="Municipal Roles List"
          createButtonLabel="Create Municipal Role"
          headers={headers}
          rows={rows}
          link="create"
          showEyeViewIcon={false}
          showFilterIcon={false}
          getMenuItems={getMenuItems}
        />
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default MunicipalRoleList;
