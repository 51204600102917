import React, { useEffect, useState } from "react";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import NotFound from "../../../components/NotFound/NotFound";

const EditWardInformation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeURL = location.pathname.split("/")[3];

  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    ward: "",
    address: "",
    contactNumber: "",
    email: "",
    image: "",
  });
  const [wardNo, setWardNo] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/wardinfo/detail`,
          {
            ward: {
              wardNumber: `${activeURL}`,
            },
          }
        );
        setData(response.data);
        setFormData({
          ward: response.data.data.ward.wardNumber,
          address: response.data.data.address,
          contactNumber: response.data.data.contactNumber,
          email: response.data.data.email,
          image: response.data.data.image,
        });
      } catch (error) {
        <NotFound />;
      }
    };

    const fetchWards = async () => {
      try {
        const wards = await adminRequest.get(`${BASE_URL}/wardNumbers/get`);
        if (isMounted) {
          const sortedWards = wards.data.data.sort(
            (a, b) => parseInt(a.wardNumber) - parseInt(b.wardNumber)
          );
          setWardNo(sortedWards);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch wards at the moment");
        }
      }
    };

    fetchData();
    fetchWards();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const changes = `
        <div className="editedChanges" style="font-size:16px; display:flex; flex-direction:column; align-items:center; gap:7px; padding:5px;">
          <span className="updatedFullName" style="display:flex; flex-direction:row; gap:10px;">
            <span className="editedChangesTitle"><strong>Email:</strong></span>
            <span>${formData.email}</span>
          </span>
          <span className="updatedMobileNumber" style="display:flex; flex-direction:row; gap:10px;">
            <span className="editedChangesTitle"><strong>Contact Number:</strong></span>
            <span>${formData.contactNumber}</span>
          </span>
          <span className="updatedAddress" style="display:flex; flex-direction:row; gap:10px;">
            <span className="editedChangesTitle"><strong>Address:</strong></span>
            <span>${formData.address}</span>
          </span>
        </div>
      `;

    const result = await Swal.fire({
      title: "Confirm Changes",
      html: changes,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Yes, save changes!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      try {
        const response = await toast.promise(
          adminRequest.post(`${BASE_URL}/wardinfo/update`, {
            ward: {
              wardNumber: activeURL,
            },
            contactNumber: formData.contactNumber,
            address: formData.address,
            email: formData.email,
            image: formData.image,
          }),
          {
            pending: "Your request is being processed",
          }
        );
        if (response.data.code == 0) {
          toast.success(response.data.message, {
            autoClose: 1500,
            onClose: () => navigate(-1),
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        <NotFound />;
      }
    }
  };

  const fields = [
    {
      name: "ward",
      label: "Ward No",
      type: "select",
      value: formData.ward,
      onChange: handleChange,
      options: [
        { label: "Select Ward No.", value: "" },
        ...wardNo.map((ward) => ({
          label: ward.wardNumber,
          value: ward.wardNumber,
        })),
      ],
      isDisabled: true,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      onChange: handleChange,
    },
    {
      name: "contactNumber",
      label: "Contact Number",
      type: "text",
      value: formData.contactNumber,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "image",
      label: "Image",
      type: "file",
      value: formData.image,
      onChange: handleChange,
    },
  ];

  return (
    <div className="editContainer">
      <CustomForm
        header="Edit Ward Information"
        fields={fields}
        createButtonLabel="Update"
        flexDirection="row"
        onSubmit={handleSubmit}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditWardInformation;
