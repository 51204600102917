import React, { useEffect, useState } from "react";
import "./Settings.scss";
import Card from "../../components/Card/Card";
import {
  MdGroups,
  MdMiscellaneousServices,
  MdOutlineAppSettingsAlt,
  MdOutlineAttachEmail,
  MdOutlineTextsms,
} from "react-icons/md";
import { BsCalendarDate } from "react-icons/bs";
import { IoUmbrellaOutline } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { adminRequest } from "../../utils/requestMethod";
import { BASE_URL } from "../../utils/config";
import Loader from "../../components/Loader/Loader";
const iconMapping = {
  MdGroups,
  MdMiscellaneousServices,
  FaRegFileAlt,
  MdOutlineAppSettingsAlt,
  MdOutlineAttachEmail,
  MdOutlineTextsms,
  BsCalendarDate,
  IoUmbrellaOutline,
};
const Settings = () => {
  const [settings, setSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchSettings = async () => {
      setIsLoading(true);
      try {
        const response = await adminRequest.get(`${BASE_URL}/navigation`);
        if (isMounted) {
          const navigationData = response.data.data;

          // Find the settings group
          const settingsGroup = navigationData.find(
            (group) => group.uiGroupName === "Settings"
          );

          if (settingsGroup) {
            // Use a Set to filter out duplicate roles
            const uniqueRoles = Array.from(
              new Set(settingsGroup.roles.map((role) => role.name))
            ).map((name) =>
              settingsGroup.roles.find((role) => role.name === name)
            );
            setSettings(uniqueRoles);
          }
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch services");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchSettings();
    return () => {
      isMounted = false;
    };
  }, []);

  if (!settings) return <Loader />;

  const sortedData = settings.sort((a, b) => a.position - b.position);

  return (
    <div className="settingPageContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="settingPageContents">
          <div className="headerTitle">Settings</div>
          <div className="settingsCard">
            {sortedData.map((item, index) => {
              const IconComponent = iconMapping[item.icon];
              return (
                <Link
                  to={`${item.navigation}`}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <Card
                    icon={IconComponent ? <IconComponent /> : null}
                    title={item.name}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Settings;
