import React, { useEffect, useState } from "react";
import CustomForm from "../../components/CustomForm/CustomForm";
import { adminRequest, updateAuthToken } from "../../utils/requestMethod";
import { BASE_URL } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Swal from "sweetalert2";

const EditProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeURL = location.pathname.split("/")[3];
  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    address: "",
    image: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/admin/viewProfile`
        );
        setData(response.data);
        setFormData({
          name: response.data.data.name,
          mobileNumber: response.data.data.mobileNumber,
          address: response.data.data.address,
        });
      } catch (error) {
        toast.error(error.message || "Failed to fetch data");
      }
    };
    fetchData();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const changes = `
        <div className="editedChanges" style="font-size:16px; display:flex; flex-direction:column; align-items:center; gap:7px; padding:5px;">
          <span className="updatedFullName" style="display:flex; flex-direction:row; gap:10px;">
            <span className="editedChangesTitle"><strong>Full Name:</strong></span>
            <span>${formData.name}</span>
          </span>
          <span className="updatedMobileNumber" style="display:flex; flex-direction:row; gap:10px;">
            <span className="editedChangesTitle"><strong>Mobile Number:</strong></span>
            <span>${formData.mobileNumber}</span>
          </span>
          <span className="updatedAddress" style="display:flex; flex-direction:row; gap:10px;">
            <span className="editedChangesTitle"><strong>Address:</strong></span>
            <span>${formData.address}</span>
          </span>
        </div>
      `;

    const result = await Swal.fire({
      title: "Confirm Changes",
      html: changes,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Yes, save changes!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      e.preventDefault();

      const formDataToSend = new FormData();
      formDataToSend.append("image", formData.image);

      formDataToSend.append(
        "profile",
        new Blob(
          [
            JSON.stringify({
              name: formData.name,
              mobileNumber: formData.mobileNumber,
              address: formData.address,
            }),
          ],
          { type: "application/json" }
        )
      );

      try {
        const response = await toast.promise(
          adminRequest.post(`${BASE_URL}/admin/updateProfile`, formDataToSend),
          {
            pending: "Your request is being processed",
          }
        );
        if (response.data.code == 0) {
          toast.success(response.data.message, {
            autoClose: 1500,
            onClose: () => navigate(-1),
          });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message || "Failed to update data");
      }
    }
  };

  const fields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "text",
      value: formData.mobileNumber,
      onChange: handleChange,
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      onChange: handleChange,
    },
    {
      name: "image",
      label: "Profile Picture",
      type: "file",
      value: formData.image,
      onChange: handleImageChange,
    },
  ];

  return (
    <div className="editContainer">
      <CustomForm
        header="Edit Profile"
        fields={fields}
        createButtonLabel="Update"
        flexDirection="column"
        onSubmit={handleSubmit}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditProfile;
