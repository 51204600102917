import React, { useEffect, useState } from "react";
import "./MayorProfile.scss";
import { useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";
import { GiOfficeChair } from "react-icons/gi";
import { adminRequest } from "../../utils/requestMethod";
import { BASE_URL, IMG_URL } from "../../utils/config";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import NotFound from "../../components/NotFound/NotFound";
const MayorProfile = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  const [mayor, setMayor] = useState([]);
  const [deputyMayor, setDeputyMayor] = useState([]);

  const fetchMayorData = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/municipalProfile/mayor`
      );
      setMayor(response.data.data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Something went wrong on server",
        icon: "error",
      });
    }
  };

  const fetchDeputyMayorData = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/municipalProfile/deputyMayor`
      );
      setDeputyMayor(response.data.data);
    } catch (error) {
      return <NotFound />;
    }
  };

  useEffect(() => {
    fetchMayorData();
    fetchDeputyMayorData();
  }, []);

  if (!mayor || !mayor.name || !deputyMayor || !deputyMayor.name) {
    return <Loader />;
  }

  return (
    <div className="mayorProfileContainer">
      <div className="header">
        <span className="leftTitle">
          <FaArrowLeftLong onClick={handleBackClick} className="icon" />
          Mayor Profile
        </span>
      </div>
      <div className="mayorProfileContents">
        <div className="mayor">
          <div className="left">
            <img
              src={`${IMG_URL}${mayor.image}`}
              alt="mayor"
              className="mayorImage"
            />
          </div>
          <div className="right">
            <span className="name">{mayor.name}</span>
            <span className="post">{mayor.post}</span>
            <span className="emailContainer">
              <MdOutlineEmail className="emailIcon" />
              <span className="email">{mayor.email}</span>
            </span>
            <span className="phoneContainer">
              <MdOutlinePhone className="phoneIcon" />
              <span className="phone">{mayor.contact}</span>
            </span>
            <span className="tenureContainer" title="In Office Since">
              <GiOfficeChair className="tenureIcon" />
              <span className="tenureStart">{mayor.tenureStart}</span>
            </span>
          </div>
        </div>
        <div className="mayor">
          <div className="left">
            <img
              src={`${IMG_URL}${deputyMayor.image}`}
              alt="mayor"
              className="mayorImage"
            />
          </div>
          <div className="right">
            <span className="name">{deputyMayor.name}</span>
            <span className="post">{deputyMayor.post}</span>
            <span className="emailContainer">
              <MdOutlineEmail className="emailIcon" />
              <span className="email">{deputyMayor.email}</span>
            </span>
            <span className="phoneContainer">
              <MdOutlinePhone className="phoneIcon" />
              <span className="phone">{deputyMayor.contact}</span>
            </span>
            <span className="tenureContainer" title="In Office Since">
              <GiOfficeChair className="tenureIcon" />
              <span className="tenureStart">{deputyMayor.tenureStart}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MayorProfile;
