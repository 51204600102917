import React from "react";
import "./HomePage.scss";
import { Route, Routes } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewsList from "../News/NewsList/NewsList";
import CreateNews from "../News/CreateNews/CreateNews";
import PoliceStationList from "../PoliceStation/PoliceStationList/PoliceStationList";
import CreatePoliceStation from "../PoliceStation/CreatePoliceStation/CreatePoliceStation";
import TourismAreaList from "../TourismArea/TourismAreaList/TourismAreaList";
import CreateTourismArea from "../TourismArea/CreateTourismArea/CreateTourismArea";
import HealthPostList from "../HealthPost/HealthPostList/HealthPostList";
import CreateHealthPost from "../HealthPost/CreateHealthPost/CreateHealthPost";
import EventList from "../Event/EventList/EventList";
import CreateEvent from "../Event/CreateEvent/CreateEvent";
import Settings from "../Settings/Settings";
import SystemConfiguration from "../Settings/SystemConfiguration/SystemConfiguration";
import NewsCategoryList from "../Category/NewsCategory/NewsCategoryList/NewsCategoryList";
import CreateNewsCategory from "../Category/NewsCategory/CreateNewsCategory/CreateNewsCategory";
import EventCategoryList from "../Category/EventCategory/EventCategoryList/EventCategoryList";
import CreateEventCategory from "../Category/EventCategory/CreateEventCategory/CreateEventCategory";
import TourismAreaCategoryList from "../Category/TourismAreaCategory/TourismAreaCategoryList/TourismAreaCategoryList";
import CreateTourismAreaCategory from "../Category/TourismAreaCategory/CreateTourismAreaCategory/CreateTourismAreaCategory";
import EditNews from "../News/EditNews/EditNews";
import HealthServiceCategoryList from "../Category/HealthServiceCategory/HealthServiceCategoryList/HealthServiceCategoryList";
import CreateHealthServiceCategory from "../Category/HealthServiceCategory/CreateHealthServiceCategory/CreateHealthServiceCategory";
import AdminList from "../Admin/AdminList/AdminList";
import AdminDetails from "../Admin/AdminDetails/AdminDetails";
import EducationLevelList from "../Category/Education/EducationLevel/EducationLevelList";
import CreateEducationLevel from "../Category/Education/EducationLevel/CreateEducationLevel";
import EducationOwnershipList from "../Category/Education/EducationOwnership/EducationOwnershipList";
import CreateEducationOwnership from "../Category/Education/EducationOwnership/CreateEducationOwnership";
import EducationList from "../Education/EducationList/EducationList";
import CreateEducation from "../Education/CreateEducation/CreateEducation";
import UserProfile from "../UserProfile/UserProfile";
import ChangePassword from "../ChangePassword/ChangePassword";
import AccessGroupList from "../AccessGroup/AccessGroupList/AccessGroupList";
import CreateAccessGroup from "../AccessGroup/CreateAccessGroup/CreateAccessGroup";
import CreateAdmin from "../Admin/CreateAdmin/CreateAdmin";
import Services from "../Services/Services";
import { useAuth } from "../../auth/useAuth";
import ViewNews from "../News/ViewNews/ViewNews";
import EditEvent from "../Event/EditEvent/EditEvent";
import EditHealthPost from "../HealthPost/EditHealthPost/EditHealthPost";
import EditEducation from "../Education/EditEducation/EditEducation";
import EditTourismArea from "../TourismArea/EditTourismArea/EditTourismArea";
import EditPoliceStation from "../PoliceStation/EditPoliceStation/EditPoliceStation";
import EventDetails from "../Event/EventDetails/EventDetails";
import HealthPostDetails from "../HealthPost/HealthPostDetails/HealthPostDetails";
import EducationDetails from "../Education/EducationDetails/EducationDetails";
import TourismAreaDetails from "../TourismArea/TourismAreaDetails/TourismAreaDetails";
import PoliceStationDetails from "../PoliceStation/PoliceStationDetails/PoliceStationDetails";
import HelpLineCategory from "../Category/HelpLine/HelpLineCategoryList/HelpLineCategory";
import CreateHelpLineCategory from "../Category/HelpLine/CreateHelpLineCategory/CreateHelpLineCategory";
import HelpLineList from "../HelpLine/HelpLineList/HelpLineList";
import CreateHelpLine from "../HelpLine/CreateHelpLine/CreateHelpLine";
import EditHelpLine from "../HelpLine/EditHelpLine/EditHelpLine";
import FinanceCategoryList from "../Category/FinanceCategory/FinanceCategoryList/FinanceCategoryList";
import CreateFinanceCategory from "../Category/FinanceCategory/CreateFinanceCategory/CreateFinanceCategory";
import FinanceList from "../Finance/FinanceList/FinanceList";
import CreateFinance from "../Finance/CreateFinance/CreateFinance";
import EditFinance from "../Finance/EditFinance/EditFinance";
import FinanceDetails from "../Finance/FinanceDetails/FinanceDetails";
import QueryList from "../Query/QueryList/QueryList";
import QueryDetails from "../Query/QueryDetails/QueryDetails";
import CustomerList from "../Customer/CustomerList/CustomerList";
import CustomerDetails from "../Customer/CustomerDetails/CustomerDetails";
import ViewTermsAndCondition from "../TermsAndCondition/ViewTermsAndCondition/ViewTermsAndCondition";
import EditTermsAndCondition from "../TermsAndCondition/EditTermsAndCondition/EditTermsAndCondition";
import Dashboard from "../Dashboard/Dashboard";
import AppConfiguration from "../Settings/AppConfiguration/AppConfiguration";
import AppMenu from "../Settings/AppConfiguration/AppMenu/AppMenu";
import CreateAppMenu from "../Settings/AppConfiguration/CreateAppMenu/CreateAppMenu";
import EmailTemplateList from "../EmailTemplate/EmailTemplateList/EmailTemplateList";
import EditEmailTemplate from "../EmailTemplate/EditEmailTemplate/EditEmailTemplate";
import EditAdmin from "../Admin/EditAdmin/EditAdmin";
import NoticeList from "../Notice/NoticeList/NoticeList";
import Laws from "../Laws/LawList/Laws";
import { WardList } from "../Ward/WardList/WardList";
import CreateWard from "../Ward/CreateWard/CreateWard";
import CreateNotice from "../Notice/CreateNotice/CreateNotice";
import ActivityList from "../ActivityLog/ActivityList";
import WardInformationList from "../WardInformation/WardInformationList/WardInformationList";
import CreateWardInformation from "../WardInformation/CreateWardInformation/CreateWardInformation";
import EditWardInformation from "../WardInformation/EditWardInformation/EditWardInformation";
import MunicipalAuthoritiesList from "../MunicipalAuthorities/MunicipalAuthoritiesList/MunicipalAuthoritiesList";
import CreateMunicipalAuthorities from "../MunicipalAuthorities/CreateMunicipalAuthorities/CreateMunicipalAuthorities";
import CreateLaw from "../Laws/CreateLaw/CreateLaw";
import MunicipalRoleList from "../MunicipalRole/MunicipalRoleList/MunicipalRoleList";
import CreateMunicipalRole from "../MunicipalRole/CreateMunicipalRole/CreateMunicipalRole";
import EditMunicipalAuthorities from "../MunicipalAuthorities/EditMunicipalAuthorities/EditMunicipalAuthorities";
import EditProfile from "../EditProofile/EditProfile";
import ChangeEmail from "../ChangeEmail/ChangeEmail";
import LocalLevelConfig from "../LocalLevelConfig/LocalLevelConfig";
import MunicipalProfile from "../MunicipalProfile/MunicipalProfile";
import AccessGroupDetails from "../AccessGroup/AccessGroupDetails/AccessGroupDetails";
import MyMunicipal from "../MyMunicipal/MyMunicipal";
import MayorProfile from "../MayorProfile/MayorProfile";
import RepresentativeProfile from "../RepresentativeProfile/RepresentativeProfile";
import EmployeeProfile from "../EmployeeProfile/EmployeeProfile";

const Homepage = () => {
  useAuth();
  return (
    <div className="homepageContainer">
      <div className="homepageContents">
        <div className="homepage__top">
          <div className="navbarArea">
            <Navbar />
          </div>
        </div>
        <div className="middle">
          <div className="homepageContents__left">
            <div className="sidebarArea">
              <Sidebar />
            </div>
          </div>
          <div className="homepageContents__right">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/myMunicipal" element={<MyMunicipal />} />
              <Route
                path="/myMunicipal/municipalProfile"
                element={<MunicipalProfile />}
              />
              <Route
                path="/myMunicipal/mayorProfile"
                element={<MayorProfile />}
              />
              <Route
                path="/myMunicipal/representativeProfile"
                element={<RepresentativeProfile />}
              />
              <Route
                path="/myMunicipal/employeeProfile"
                element={<EmployeeProfile />}
              />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/editProfile" element={<EditProfile />} />
              <Route path="/changeEmail" element={<ChangeEmail />} />
              <Route path="/activity" element={<ActivityList />} />
              <Route path="/services" element={<Services />} />
              <Route
                path="/termsAndConditions"
                element={<ViewTermsAndCondition />}
              />
              <Route
                path="/termsAndConditions/edit"
                element={<EditTermsAndCondition />}
              />
              <Route path="/queries" element={<QueryList />} />
              <Route path="/queries/view/:code" element={<QueryDetails />} />
              <Route path="/users" element={<CustomerList />} />
              <Route path="/users/view/:code" element={<CustomerDetails />} />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/accessGroup" element={<AccessGroupList />} />
              <Route
                path="/accessGroup/create"
                element={<CreateAccessGroup />}
              />
              <Route
                path="/accessGroup/view/:name"
                element={<AccessGroupDetails />}
              />
              <Route path="adminUser" element={<AdminList />} />
              <Route path="/adminUser/create" element={<CreateAdmin />} />
              <Route path="/adminUser/view/:email" element={<AdminDetails />} />
              <Route path="/adminUser/edit/:email" element={<EditAdmin />} />
              <Route path="news" element={<NewsList />} />
              <Route path="news/create" element={<CreateNews />} />
              <Route path="/news/edit/:code" element={<EditNews />} />
              <Route path="/news/view/:code" element={<ViewNews />} />
              <Route path="police" element={<PoliceStationList />} />
              <Route path="police/create" element={<CreatePoliceStation />} />
              <Route path="police/edit/:code" element={<EditPoliceStation />} />
              <Route
                path="police/view/:code"
                element={<PoliceStationDetails />}
              />
              <Route path="tourismAreas" element={<TourismAreaList />} />
              <Route
                path="tourismAreas/create"
                element={<CreateTourismArea />}
              />
              <Route
                path="tourismAreas/edit/:code"
                element={<EditTourismArea />}
              />
              <Route
                path="tourismAreas/view/:code"
                element={<TourismAreaDetails />}
              />
              <Route path="healthService" element={<HealthPostList />} />
              <Route
                path="healthService/create"
                element={<CreateHealthPost />}
              />
              <Route
                path="/healthService/edit/:code"
                element={<EditHealthPost />}
              />
              <Route
                path="/healthService/view/:code"
                element={<HealthPostDetails />}
              />
              <Route path="education" element={<EducationList />} />
              <Route path="education/create" element={<CreateEducation />} />
              <Route path="/education/edit/:code" element={<EditEducation />} />
              <Route
                path="/education/view/:code"
                element={<EducationDetails />}
              />
              <Route path="event" element={<EventList />} />
              <Route path="/event/view/:code" element={<EventDetails />} />
              <Route path="/event/create" element={<CreateEvent />} />
              <Route path="/event/edit/:code" element={<EditEvent />} />
              <Route path="helpLine" element={<HelpLineList />} />
              <Route path="/helpLine/create" element={<CreateHelpLine />} />
              <Route path="/helpLine/edit/:code" element={<EditHelpLine />} />
              <Route path="finance" element={<FinanceList />} />
              <Route path="/finance/view/:code" element={<FinanceDetails />} />
              <Route path="/finance/create" element={<CreateFinance />} />
              <Route path="/finance/edit/:code" element={<EditFinance />} />

              <Route path="/notices" element={<NoticeList />} />
              <Route path="/notices/create" element={<CreateNotice />} />

              <Route path="/laws" element={<Laws />} />
              <Route path="/laws/create" element={<CreateLaw />} />
              <Route
                path="/wardInformation"
                element={<WardInformationList />}
              />
              <Route
                path="/wardInformation/create"
                element={<CreateWardInformation />}
              />
              <Route
                path="/wardInformation/edit/:ward"
                element={<EditWardInformation />}
              />
              <Route
                path="/municipalAuthorities"
                element={<MunicipalAuthoritiesList />}
              />
              <Route
                path="/municipalAuthorities/create"
                element={<CreateMunicipalAuthorities />}
              />
              <Route
                path="/municipalAuthorities/edit/:code"
                element={<EditMunicipalAuthorities />}
              />
              <Route path="setting" element={<Settings />} />
              <Route
                path="/systemConfiguration"
                element={<SystemConfiguration />}
              />
              <Route
                path="/systemConfiguration/localLevelConfig"
                element={<LocalLevelConfig />}
              />
              <Route
                path="/systemConfiguration/municipalRole"
                element={<MunicipalRoleList />}
              />
              <Route
                path="/systemConfiguration/municipalRole/create"
                element={<CreateMunicipalRole />}
              />
              <Route
                path="/systemConfiguration/newsCategory"
                element={<NewsCategoryList />}
              />
              <Route
                path="/systemConfiguration/newsCategory/create"
                element={<CreateNewsCategory />}
              />
              <Route
                path="/systemConfiguration/eventCategory"
                element={<EventCategoryList />}
              />
              <Route
                path="/systemConfiguration/eventCategory/create"
                element={<CreateEventCategory />}
              />
              <Route
                path="/systemConfiguration/tourismAreaCategory"
                element={<TourismAreaCategoryList />}
              />
              <Route
                path="/systemConfiguration/tourismAreaCategory/create"
                element={<CreateTourismAreaCategory />}
              />
              <Route
                path="/systemConfiguration/healthServiceCategory"
                element={<HealthServiceCategoryList />}
              />
              <Route
                path="/systemConfiguration/healthServiceCategory/create"
                element={<CreateHealthServiceCategory />}
              />
              <Route
                path="/systemConfiguration/educationLevel"
                element={<EducationLevelList />}
              />
              <Route
                path="/systemConfiguration/educationLevel/create"
                element={<CreateEducationLevel />}
              />
              <Route
                path="/systemConfiguration/educationOwnership"
                element={<EducationOwnershipList />}
              />
              <Route
                path="/systemConfiguration/educationOwnership/create"
                element={<CreateEducationOwnership />}
              />
              <Route
                path="/systemConfiguration/helpLineCategory"
                element={<HelpLineCategory />}
              />
              <Route
                path="/systemConfiguration/helpLineCategory/create"
                element={<CreateHelpLineCategory />}
              />
              <Route
                path="/systemConfiguration/financeCategory"
                element={<FinanceCategoryList />}
              />
              <Route
                path="/systemConfiguration/financeCategory/create"
                element={<CreateFinanceCategory />}
              />
              <Route path="/appConfiguration" element={<AppConfiguration />} />
              <Route path="/appConfiguration/appMenu" element={<AppMenu />} />
              <Route
                path="/appConfiguration/appMenu/create"
                element={<CreateAppMenu />}
              />
              <Route path="/setting/email" element={<EmailTemplateList />} />
              <Route
                path="/setting/email/:name"
                element={<EditEmailTemplate />}
              />
              <Route
                path="/systemConfiguration/wardList"
                element={<WardList />}
              />
              <Route
                path="/systemConfiguration/wardList/create"
                element={<CreateWard />}
              />
            </Routes>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};
export default Homepage;
