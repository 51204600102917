import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomForm from "../../components/CustomForm/CustomForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest } from "../../utils/requestMethod";
import { BASE_URL } from "../../utils/config";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";

const LocalLevelConfig = () => {
  const initialFormData = {
    name: "",
    province: "",
    district: "",
    localLevel: "",
    description: "",
    image: null,
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [data, setData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [province, setProvince] = useState([]);
  const [district, setDistrict] = useState([]);
  const [localLevel, setLocalLevel] = useState([]);
  const [localLevelId, setLocalLevelId] = useState();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminRequest.get(
          `${BASE_URL}/localLevelConfig/get`
        );
        const configData = response.data?.data?.[0];
        setData(configData);
        setFormData({
          name: configData.name,
          province: configData.province?.id,
          district: configData.district?.id,
          localLevel: configData.localLevel?.localLevelCode,
          description: configData.description,
          image: configData.image,
        });
        setLocalLevelId(configData.id);
        console.log(localLevelId);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchProvince = async () => {
      try {
        const response = await adminRequest.get(`${BASE_URL}/province/get`);
        setProvince(response.data.data);

        if (formData.province) {
          const fetchDistrict = async () => {
            try {
              const districtResponse = await adminRequest.post(
                `${BASE_URL}/district/get`,
                {
                  province: { id: formData.province },
                }
              );
              setDistrict(districtResponse.data.data);
            } catch (error) {
              console.log(error);
            }
          };
          fetchDistrict();
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProvince();
  }, [formData.province]);

  useEffect(() => {
    if (formData.district) {
      const fetchLocalLevel = async () => {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/localLevel/get`,
            {
              district: { id: formData.district },
            }
          );
          setLocalLevel(response.data.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchLocalLevel();
    }
  }, [formData.district]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.image);

    formDataToSend.append(
      "locallevelconfig ",
      new Blob(
        [
          JSON.stringify({
            id: localLevelId,
            name: formData.name,
            description: formData.description,
            province: {
              id: formData.province,
            },
            district: {
              id: formData.district,
            },
            localLevel: {
              localLevelCode: formData.localLevel,
            },
          }),
        ],
        { type: "application/json" }
      )
    );

    Swal.fire({
      title: "Are you sure?",
      text: "You can't revert this. This will update the current local level data.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await toast.promise(
            adminRequest.post(
              `${BASE_URL}/localLevelConfig/update`,
              formDataToSend
            ),
            {
              pending: "Your request is being processed",
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Success",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Failed!",
            text: "Something went wrong on server",
            icon: "error",
          });
        }
      }
    });
  };

  if (!data || Object.keys(data).length === 0) {
    return <Loader />;
  }

  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "province",
      label: "Province",
      type: "select",
      value: formData.province,
      onChange: handleChange,
      options: [
        { label: "Select Province", value: "" },
        ...province.map((p) => ({
          label: p.province,
          value: p.id,
        })),
      ],
    },
    {
      name: "district",
      label: "District",
      type: "select",
      value: formData.district,
      onChange: handleChange,
      options: [
        { label: "Select District", value: "" },
        ...district.map((d) => ({
          label: d.districtName,
          value: d.id,
        })),
      ],
    },
    {
      name: "localLevel",
      label: "Local Level",
      type: "select",
      value: formData.localLevel,
      onChange: handleChange,
      options: [
        { label: "Select Local Level", value: "" },
        ...localLevel.map((l) => ({
          label: l.localLevel,
          value: l.localLevelCode,
        })),
      ],
    },
    {
      name: "image",
      label: "Logo",
      type: "file",
      value: formData.description,
      onChange: handleImageChange,
      tail: "Please make sure to upload transparent logo",
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: formData.description,
      onChange: handleChange,
    },
  ];

  return (
    <div className="localLevelConfigContents">
      <CustomForm
        header="Local Level Configuration"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Save"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default LocalLevelConfig;
