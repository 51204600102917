import React, { useEffect, useState, PureComponent } from "react";
import "./MunicipalProfile.scss";
import { adminRequest } from "../../utils/requestMethod";
import { BASE_URL, IMG_URL } from "../../utils/config";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Loader from "../../components/Loader/Loader";

const MunicipalProfile = () => {
  const navigate = useNavigate();
  const [localLevel, setLocalLevel] = useState([]);
  const [censusApi, setCensusApi] = useState([]);
  const [censusData, setCensusData] = useState([]);
  const [wardPopulationApi, setWardPopulationApi] = useState([]);
  const [wardPopulation, setWardPopulation] = useState([]);
  const [maritialStatusApi, setMaritialStatusApi] = useState([]);
  const [maritalStatusData, setMaritalStatusData] = useState([]);
  const [birthRegApi, setBirthRegApi] = useState([]);
  const [birthRegData, setBirthRegData] = useState([]);

  const fetchLocalLevelData = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/localLevelConfig/get`
      );
      setLocalLevel(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCensusApi = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/localLevel/census/get`
      );
      setCensusApi(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCensusData = async () => {
    if (censusApi) {
      try {
        const response = await adminRequest.get(censusApi);
        setCensusData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const fetchWardCensusApi = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/wardLevel/census/get`
      );
      setWardPopulationApi(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchWardCensusData = async () => {
    if (censusApi) {
      try {
        const response = await adminRequest.get(wardPopulationApi);
        setWardPopulation(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const fetchMaritialStatusApi = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/localLevel/maritalStatus/get`
      );
      setMaritialStatusApi(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMaritialStatusData = async () => {
    if (censusApi) {
      try {
        const response = await adminRequest.get(maritialStatusApi);
        setMaritalStatusData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const fetchBirthRegApi = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/localLevel/birthRegistration/get`
      );
      setBirthRegApi(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBirthRegData = async () => {
    if (censusApi) {
      try {
        const response = await adminRequest.get(birthRegApi);
        setBirthRegData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchMaritialStatusApi();
    fetchBirthRegApi();
    fetchCensusApi();
    fetchLocalLevelData();
    fetchWardCensusApi();
  }, []);

  useEffect(() => {
    fetchCensusData();
  }, [censusApi]);

  useEffect(() => {
    fetchWardCensusData();
  }, [wardPopulationApi]);

  useEffect(() => {
    fetchMaritialStatusData();
  }, [maritialStatusApi]);

  useEffect(() => {
    fetchBirthRegData();
  }, [birthRegApi]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const populationDistData = censusData?.sex_chart?.series
    ? [
        { name: "Male", population: censusData.sex_chart.series[0] },
        { name: "Female", population: censusData.sex_chart.series[1] },
      ]
    : [];

  const wardCensusData =
    wardPopulation && wardPopulation.categories && wardPopulation.series
      ? wardPopulation.categories.map((category, index) => ({
          name: category,
          male: wardPopulation.series[0].data[index],
          female: wardPopulation.series[1].data[index],
        }))
      : [];

  const maritalData =
    maritalStatusData &&
    maritalStatusData.categories &&
    maritalStatusData.series
      ? maritalStatusData.categories.map((category, index) => ({
          name: category,
          total: maritalStatusData.series[0].data[index],
          male: maritalStatusData.series[1].data[index],
          female: maritalStatusData.series[2].data[index],
          totalCount: maritalStatusData.countSeries[0].data[index],
          maleCount: maritalStatusData.countSeries[1].data[index],
          femaleCount: maritalStatusData.countSeries[2].data[index],
        }))
      : [];

  const birthRegDataFormatted =
    birthRegData && birthRegData.categories && birthRegData.series
      ? birthRegData.categories.map((category, index) => ({
          name: category,
          population: birthRegData.series[index],
        }))
      : [];

  if (
    !censusData ||
    !censusData.sex_chart ||
    !localLevel ||
    !wardPopulation ||
    !maritalStatusData
  ) {
    return <Loader />;
  }
  return (
    <div className="municipalProfileContainer">
      <div className="header">
        <span className="leftTitle">
          <FaArrowLeftLong onClick={handleBackClick} className="icon" />
          Municipal Profile
        </span>
      </div>
      <div className="municipalProfileContents">
        <div className="municipalProfileContentsTop">
          <div className="contentsLeft">
            <img
              src={`${IMG_URL}${localLevel.image}`}
              className="localLevelLogo"
              alt="local_level_logo"
            />
          </div>
          <div className="contentsRight">
            <span className="localLevelName">{localLevel.name}</span>
            <span className="localLevelDescription">
              {localLevel.description}
            </span>
          </div>
        </div>
        <div className="municipalProfileContentsBottom">
          <div className="left">
            <div className="totalPopulation">
              <span className="header">Total Population</span>
              <span className="populationData">{censusData.total}</span>
            </div>
            <BarChart
              width={500}
              height={100}
              data={populationDistData}
              layout="vertical"
              margin={{
                top: 5,
                bottom: 5,
              }}
            >
              <XAxis type="number" domain={[0, 100]} unit="%" hide />
              <YAxis
                type="category"
                dataKey="name"
                width={60}
                axisLine={false}
                tickLine={false}
              />
              <Bar dataKey="population" fill="#2384b4">
                <LabelList
                  dataKey="population"
                  position="right"
                  formatter={(value) => `${value} %`}
                />
              </Bar>
            </BarChart>
          </div>
          <div className="right">
            <div className="sexRatio">
              <span className="header">Sex Ratio</span>
              <span className="sexRatioData">
                <span>{censusData.sex_ratio} </span>Male per 100 Female
              </span>
            </div>
            <div className="populationDensity">
              <span className="header">Population Density</span>
              <span className="populationDensityData">
                <span>{censusData.pop_density}</span> Persons per sq. km.
              </span>
            </div>
          </div>
        </div>
        <div className="wardWisePopulationContainer">
          <span className="header">Ward Wise Population Distribution</span>
          <div className="chart">
            <BarChart
              width={1000}
              height={300}
              data={wardCensusData}
              margin={{
                top: 5,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="male" fill="#2384b4" />
              <Bar dataKey="female" fill="#e9c46a" />
            </BarChart>
          </div>
        </div>
        <div className="maritalStatusAndBirthRegistrationContainer">
          <div className="birthRegContainer">
            <span className="header">Birth Registration</span>
            <div className="chart">
              <BarChart
                height={300}
                width={250}
                data={birthRegDataFormatted}
                margin={{
                  top: 15,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="population" fill="#2384b4">
                  <LabelList dataKey="population" position="top" />
                </Bar>
              </BarChart>
            </div>
          </div>
          <div className="maritialStatusContainer">
            <span className="header">Marital Status</span>
            <div className="chart">
              <BarChart data={maritalData} width={750} height={300}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend />
                <Bar dataKey="male" fill="#2384b4">
                  <LabelList
                    dataKey="maleCount"
                    position="top"
                    angle={90}
                    dy={-23}
                    dx={-5}
                  />
                </Bar>
                <Bar dataKey="female" fill="#e9c46a">
                  <LabelList
                    dataKey="femaleCount"
                    position="top"
                    angle={90}
                    dy={-23}
                    dx={-5}
                  />
                </Bar>
                <Bar dataKey="total" fill="#2a9d8f">
                  <LabelList
                    dataKey="totalCount"
                    position="top"
                    angle={90}
                    dy={-23}
                    dx={-5}
                  />
                </Bar>
              </BarChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MunicipalProfile;
