import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import NotFound from "../../../components/NotFound/NotFound";

const CreateWardInformation = () => {
  const initialFormData = {
    ward: "",
    address: "",
    contactNumber: "",
    email: "",
    image: null,
  };
  const [wardNo, setWardNo] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const fetchWards = async () => {
      try {
        const wards = await adminRequest.get(`${BASE_URL}/wardNumbers/get`);
        if (isMounted) {
          const sortedWards = wards.data.data.sort(
            (a, b) => parseInt(a.wardNumber) - parseInt(b.wardNumber)
          );
          setWardNo(sortedWards);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch wards at the moment");
        }
      }
    };
    updateAuthToken();
    fetchWards();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.image);

    formDataToSend.append(
      "ward-info",
      new Blob(
        [
          JSON.stringify({
            ward: {
              wardNumber: formData.ward,
            },
            address: formData.address,
            contactNumber: formData.contactNumber,
            email: formData.email,
          }),
        ],
        { type: "application/json" }
      )
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/wardinfo/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "ward",
      label: "Ward No",
      type: "select",
      value: formData.ward,
      onChange: handleChange,
      options: [
        { label: "Select Ward No.", value: "" },
        ...wardNo.map((ward) => ({
          label: ward.wardNumber,
          value: ward.wardNumber,
        })),
      ],
    },
    {
      name: "address",
      label: "Address",
      type: "text",
      value: formData.address,
      onChange: handleChange,
    },
    {
      name: "contactNumber",
      label: "Contact Number",
      type: "text",
      value: formData.contactNumber,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "image",
      label: "Image",
      type: "file",
      value: formData.image,
      onChange: handleImageChange,
    },
  ];
  return (
    <div className="createForm">
      <CustomForm
        header="Create Ward Information"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateWardInformation;
