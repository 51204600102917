import React from "react";
import "./MyMunicipal.scss";
import Card from "../../components/Card/Card";
import { Link, useNavigate } from "react-router-dom";
import { PiBuildingOffice } from "react-icons/pi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { BsPersonSquare } from "react-icons/bs";
import { MdPeopleAlt } from "react-icons/md";
const MyMunicipal = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div className="myMunicipalContainer">
      <div className="myMunicipalContents">
        <div className="myMunicipalHeader">
          <span className="backIcon" onClick={handleBackClick}>
            <FaArrowLeftLong />
          </span>
          <span>My Municipal</span>
        </div>
        <div className="myMunicipalCard">
          <Link to="municipalProfile" style={{ textDecoration: "none" }}>
            <Card icon={<PiBuildingOffice />} title="Municipal Profile" />
          </Link>
          <Link to="mayorProfile" style={{ textDecoration: "none" }}>
            <Card icon={<BsPersonSquare />} title="Mayor Profile" />
          </Link>
          <Link to="representativeProfile" style={{ textDecoration: "none" }}>
            <Card icon={<MdPeopleAlt />} title="Representatives Profile" />
          </Link>
          <Link to="employeeProfile" style={{ textDecoration: "none" }}>
            <Card icon={<MdPeopleAlt />} title="Employees Profile" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyMunicipal;
