import React, { useEffect, useState } from "react";
import "./CreateNews.scss";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
import NotFound from "../../../components/NotFound/NotFound";
const CreateNews = () => {
  const initialFormData = {
    title: "",
    contents: "",
    category: "",
    image: null,
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newsCategory, setNewsCategory] = useState([]);
  useEffect(() => {
    let isMounted = true;
    const fetchNewsCategory = async () => {
      try {
        const newsCategories = await adminRequest.get(
          `${BASE_URL}/active/newsCategory`
        );
        if (isMounted) {
          setNewsCategory(newsCategories.data.data);
          updateAuthToken();
        }
      } catch (error) {
        if (isMounted) {
          return <NotFound />;
        }
      }
    };
    fetchNewsCategory();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      contents: value,
    }));
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.image);

    formDataToSend.append(
      "news",
      new Blob(
        [
          JSON.stringify({
            title: formData.title,
            newsCategory: {
              name: formData.category,
            },
            content: formData.contents,
          }),
        ],
        { type: "application/json" }
      )
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/news/create`, formDataToSend),
        {
          pending: "Posting News",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
        setFormData(initialFormData);
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
    } catch (error) {
      <NotFound />;
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: formData.title,
      onChange: handleChange,
      width: "750px",
      height: "30px",
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      width: "200px",
      height: "40px",
      value: formData.category || "",
      onChange: handleChange,
      options: [
        { label: "Select News Category", value: "" },
        ...newsCategory.map((c) => ({
          label: c.name,
          value: c.name,
        })),
      ],
    },
    {
      name: "image",
      label: "Image",
      type: "file",
      value: formData.image,
      onChange: handleImageChange,
    },
    {
      name: "contents",
      type: "rich-text-editor",
      value: formData.contents,
      onChange: handleEditorChange,
    },
  ];

  return (
    <div className="createNewsContainer">
      <CustomForm
        header="Create News"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Post"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateNews;
