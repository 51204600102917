import React, { useEffect, useState } from "react";
import "./AccessGroupDetails.scss";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../../utils/config";
import { FaArrowLeftLong } from "react-icons/fa6";
import Loader from "../../../components/Loader/Loader";

const AccessGroupDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const location = useLocation();
  const activeURL = decodeURIComponent(location.pathname.split("/")[3]);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/accessGroup/get/detail`,
          {
            name: `${activeURL}`,
          }
        );
        setData(response.data.data);
      } catch (error) {
        toast.error("Failed to fetch data");
      }
    };
    fetchData();
  }, [activeURL, refresh]);
  updateAuthToken();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  return (
    <div className="accessGroupDetailsContainer">
      <div className="accessGroupDetailsContents">
        <div className="accessGroupDetailsTop">
          <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
          <h1 className="headerDetails">Access Group Details</h1>
        </div>
        {data && data.accessGroupRoleMaps ? (
          <>
            <div className="accessGroupDetailsMiddle">
              <span className="nameContainer">
                <span className="nameHeader">Name</span>
                <span className="nameContents">{data.name}</span>
              </span>
              <span className="descContainer">
                <span className="descHeader">Description</span>
                <span className="descContents">{data.description}</span>
              </span>
            </div>
            <div className="accessGroupDetailsBottom">
              <span className="bottomHeader">Provided Roles</span>
              {data.accessGroupRoleMaps
                .filter(
                  (roles) => roles.roles.parentName === "Root" && roles.isActive
                )
                .sort((a, b) => a.roles.position - b.roles.position)
                .map((roles, index) => (
                  <span className="rolesList" key={index}>
                    <span className="rolesParent">
                      {index + 1}
                      {". "}
                      {roles.roles.name}
                    </span>
                    <span className="rolesChild">
                      {data.accessGroupRoleMaps
                        .filter(
                          (childRole) =>
                            childRole.roles.parentName === roles.roles.name &&
                            childRole.isActive
                        )
                        .sort((a, b) => a.roles.position - b.roles.position)
                        .map((filteredRole, childIndex) => (
                          <li key={childIndex}>
                            {filteredRole.roles.name}
                            {data.accessGroupRoleMaps
                              .filter(
                                (subChildRole) =>
                                  subChildRole.roles.parentName ===
                                    filteredRole.roles.name &&
                                  subChildRole.isActive
                              )
                              .sort(
                                (a, b) => a.roles.position - b.roles.position
                              )
                              .map((subChildRole, subChildIndex) => (
                                <ul
                                  key={subChildIndex}
                                  className="subChildRole"
                                >
                                  <li>{subChildRole.roles.name}</li>
                                </ul>
                              ))}
                          </li>
                        ))}
                    </span>
                  </span>
                ))}
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default AccessGroupDetails;
