import React, { useEffect, useState } from "react";
import "./CreateTourismArea.scss";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
const CreateTourismArea = () => {
  const initialFormData = {
    name: "",
    category: "",
    description: "",
    latitude: "",
    longitude: "",
    image: null,
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tourismCategory, setTourismCategory] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchTourismCategory = async () => {
      try {
        const tourismCategory = await adminRequest.get(
          `${BASE_URL}/active/tourismCategory`
        );
        if (isMounted) {
          setTourismCategory(tourismCategory.data.data);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch tourism category at the moment");
        }
      }
    };
    updateAuthToken();
    fetchTourismCategory();
    return () => {
      isMounted = false;
    };
  }, []);
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.image);

    formDataToSend.append(
      "tourism-area",
      new Blob(
        [
          JSON.stringify({
            name: formData.name,
            details: formData.description,
            latitude: formData.latitude,
            longitude: formData.longitude,
            tourismCategory: {
              name: formData.category,
            },
          }),
        ],
        { type: "application/json" }
      )
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/tourismArea/create`, formDataToSend),
        {
          pending: "Creating tourism area",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      console.log(error);
      toast.error("Failed to create tourism area");
    } finally {
      setIsSubmitting(false);
    }
  };

  const fields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "latitude",
      label: "Latitude",
      type: "text",
      value: formData.latitude,
      onChange: handleChange,
    },
    {
      name: "longitude",
      label: "Longitude",
      type: "text",
      value: formData.longitude,
      onChange: handleChange,
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      value: formData.category || "",
      onChange: handleChange,
      options: [
        { label: "Select Category", value: "" },
        ...tourismCategory.map((c) => ({
          label: c.name,
          value: c.name,
        })),
      ],
    },
    {
      name: "description",
      label: "Description",
      type: "textarea",
      value: formData.description,
      onChange: handleChange,
    },
    {
      name: "image",
      label: "Image",
      type: "file",
      value: formData.image,
      onChange: handleImageChange,
    },
  ];

  return (
    <div className="createTourismAreaContainer">
      <CustomForm
        header="Create Tourism Area"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateTourismArea;
