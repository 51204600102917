import React from "react";
import { BsDatabaseX } from "react-icons/bs";
import "./NoRecord.scss";
const NoRecord = () => {
  return (
    <div className="noRecordContainer">
      <div className="noRecordContents">
        <div className="noRecordIconContainer">
          <BsDatabaseX className="noRecordIcon" />
        </div>
        <div className="noRecordText">No Record Found.</div>
      </div>
    </div>
  );
};

export default NoRecord;
