import React, { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaFilter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "./EmployeeProfile.scss";
import { adminRequest } from "../../utils/requestMethod";
import CustomForm from "../../components/CustomForm/CustomForm";
import Loader from "../../components/Loader/Loader";
import NotFound from "../../components/NotFound/NotFound";
import { BASE_URL, IMG_URL } from "../../utils/config";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import { GiOfficeChair } from "react-icons/gi";
const EmployeeProfile = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [ward, setWard] = useState([]);
  const [openFilterForm, setOpenFilterForm] = useState(false);
  const [representatives, setRepresentatives] = useState([]);
  const [filterValues, setFilterValues] = useState({
    name: "",
    ward: "",
  });
  const [pageSize, setPageSize] = useState();

  const fetchWards = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/wardNumbers/get`);
      const sortedWards = response.data.data.sort(
        (a, b) => parseInt(a.wardNumber) - parseInt(b.wardNumber)
      );
      setWard(sortedWards);
    } catch (error) {
      return <NotFound />;
    }
  };

  const fetchRepresentatives = async () => {
    setIsLoading(true);
    try {
      const initialResponse = await adminRequest.post(
        `${BASE_URL}/municipalProfile/employee`,
        {
          firstRow: 0,
          pageSize: 10,
        }
      );
      setPageSize(initialResponse.data.data.total);
      const response = await adminRequest.post(
        `${BASE_URL}/municipalProfile/employee`,
        {
          firstRow: 0,
          pageSize,
        }
      );
      setRepresentatives(response.data.data.records);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWards();
    fetchRepresentatives();
  }, []);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (value === "Select Ward") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const fetchFilteredRepresentatives = async () => {
    try {
      const filterParams = {};

      if (filterValues.name) filterParams.name = filterValues.name;
      if (filterValues.ward) filterParams.ward = filterValues.ward;

      const response = await adminRequest.post(
        `${BASE_URL}/municipalProfile/employee`,
        {
          firstRow: 0,
          pageSize,
          param: filterParams,
        }
      );
      setRepresentatives(response.data.data.records);
    } catch (error) {
      return <NotFound />;
    }
  };

  const filterFields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: filterValues.name,
      onChange: handleFilterChange,
    },
    {
      name: "ward",
      label: "Ward",
      type: "select",
      value: filterValues.ward,
      onChange: handleFilterChange,
      options: [
        { label: "Select Ward", value: "" },
        ...ward.map((w) => ({
          label: w.wardNumber,
          value: w.wardNumber,
        })),
      ],
    },
  ];

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredRepresentatives();
  };

  const toggleFilterForm = () => {
    setOpenFilterForm(!openFilterForm);
  };

  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchRepresentatives();
    setFilterValues({
      name: "",
      ward: "",
    });
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="representativeProfileContainer">
          <div className="header">
            <span className="leftTitle">
              <FaArrowLeftLong onClick={handleBackClick} className="icon" />
              Employee Profile
            </span>
            <span className="rightFilter" title="Open Filter Form">
              <FaFilter className="filterIcon" onClick={toggleFilterForm} />
            </span>
          </div>
          <div className="representativeProfileContents">
            {openFilterForm && (
              <div className="filterForm">
                <CustomForm
                  createButtonLabel="Search"
                  flexDirection="row"
                  showDefaultHeader={false}
                  fields={filterFields}
                  onSubmit={handleFilterSubmit}
                  showCancelButton={false}
                  showCloseButton={true}
                  closeFilterForm={resetFilterForm}
                />
              </div>
            )}
            <div className="profileList">
              {representatives.map((representative) => (
                <div className="profile">
                  <div className="left">
                    <img
                      src={`${IMG_URL}${representative.image}`}
                      alt={representative.name}
                      className="image"
                    />
                  </div>
                  <div className="right">
                    <span className="name">{representative.name}</span>
                    <span className="post">{representative.post}</span>
                    {representative.ward ? (
                      <span className="ward">
                        Ward:{representative.ward.wardNumber}
                      </span>
                    ) : (
                      ""
                    )}

                    <span className="emailContainer">
                      <MdOutlineEmail className="emailIcon" />
                      <span className="email">{representative.email}</span>
                    </span>
                    <span className="phoneContainer">
                      <MdOutlinePhone className="phoneIcon" />
                      <span className="phone">{representative.contact}</span>
                    </span>
                    <span className="tenureContainer" title="In Office Since">
                      <GiOfficeChair className="tenureIcon" />
                      <span className="tenureStart">
                        {representative.tenureStart
                          ? representative.tenureStart
                          : "Not available"}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeeProfile;
