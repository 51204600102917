import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../../utils/requestMethod";
import { BASE_URL } from "../../../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../components/Loader/Loader";
import List from "../../../../components/List/List";
import Swal from "sweetalert2";
const AppMenu = () => {
  const headers = ["Name", "En", "NP", "Status"];
  const [rows, setRows] = useState([]);

  const fetchData = async () => {
    try {
      const data = await adminRequest.get(`${BASE_URL}/appMenu/get`);
      console.log(data.data.data);
      const fetchedRows = data.data.data.map((e) => ({
        displayData: [e.name, e.en, e.np, e.status.name],
        code: e.code,
        status: e.status.name,
      }));
      setRows(fetchedRows);
    } catch (error) {
      toast.error("Failed to fetch app menu at the moment");
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  updateAuthToken();

  const handleBlock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to block this app menu?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
      html: '<textarea id="blockReason" class="swal2-textarea" placeholder="Enter reason for blocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const blockReason = Swal.getPopup().querySelector("#blockReason").value;
        if (!blockReason) {
          Swal.showValidationMessage("Please enter a reason for block");
        }
        return { blockReason: blockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/appMenu/block`,
            {
              code: code,
              remarks: result.value.blockReason,
            }
          );
          if (response.data) {
            if (response.data.code == 0) {
              Swal.fire({
                title: "Blocked!",
                text: `${response.data.message}`,
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Failed!",
                text: `${response.data.message}`,
                icon: "error",
              });
            }
          } else {
            Swal.fire({
              title: "Something went wrong!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchData();
        } catch (error) {
          toast.error(error.message || "Failed to block");
        }
      }
    });
  };
  const handleUnblock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to unblock this app menu?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
      html: '<textarea id="unblockReason" class="swal2-textarea" placeholder="Enter reason for unblocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const unblockReason =
          Swal.getPopup().querySelector("#unblockReason").value;
        if (!unblockReason) {
          Swal.showValidationMessage("Please enter a reason for unblock");
        }
        return { unblockReason: unblockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/appMenu/unblock`,
            {
              code: code,
              remarks: result.value.unblockReason,
            }
          );
          if (response.data) {
            if (response.data.code == 0) {
              Swal.fire({
                title: "Unblocked!",
                text: `${response.data.message}`,
                icon: "success",
              });
            } else {
              Swal.fire({
                title: "Failed!",
                text: `${response.data.message}`,
                icon: "error",
              });
            }
          } else {
            Swal.fire({
              title: "Something went wrong!",
              text: "Please reload and try again.",
              icon: "error",
            });
          }
          fetchData();
        } catch (error) {
          console.log(error.message || "Failed to unblock");
        }
      }
    });
  };
  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    { link: `edit/${row.code}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.code);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.code);
          },
        },
  ];
  return (
    <div className="appMenuListContainer">
      {rows.length > 0 ? (
        <List
          title="App Menu Management"
          createButtonLabel="Create App Menu"
          headers={headers}
          rows={rows.map((row) => row.displayData)}
          link="create"
          showEyeViewIcon={false}
          showFilterIcon={false}
          getMenuItems={(row) =>
            getMenuItems(rows.find((r) => r.displayData === row))
          }
        />
      ) : (
        <Loader />
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default AppMenu;
