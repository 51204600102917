import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import "./EditEmailTemplate.scss";

const EditEmailTemplate = () => {
  const location = useLocation();
  const urlPath = decodeURIComponent(location.pathname.split("/email/")[1]);
  const emailTemplateRef = useRef(null);
  const [data, setData] = useState({});
  const [template, setTemplate] = useState("");
  const navigate = useNavigate();
  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  const fetchEmailTemplate = async () => {
    try {
      const response = await adminRequest.post(
        `${BASE_URL}/emailTemplate/detail`,
        {
          name: `${urlPath}`,
        }
      );
      setData(response.data.data);
      setTemplate(response.data.data.template);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    fetchEmailTemplate();
  }, [urlPath]);

  updateAuthToken();

  if (!data && !data.template) {
    return <Loader />;
  }
  return (
    <div className="editEmailTemplateContainer">
      <div className="editEmailTemplateContents">
        <div className="editEmailTemplateHeader">
          <span>
            <FaArrowLeftLong className="backArrow" onClick={handleBackClick} />
          </span>
          <span>Edit Email Template</span>
        </div>
        <div className="editEmailTemplateBody">
          <JoditEditor
            ref={emailTemplateRef}
            value={template}
            tabIndex={1}
            onChange={(newContent) => setTemplate(newContent)}
          />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditEmailTemplate;
