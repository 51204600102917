import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import "./EventList.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { truncateContents } from "../../../utils/truncateContents";
import Loader from "../../../components/Loader/Loader";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { trimDate } from "../../../utils/dateUtil";
import Swal from "sweetalert2";
import NotFound from "../../../components/NotFound/NotFound";
import Pagination from "../../../components/pagination/Pagination";
const EventList = () => {
  const headers = ["Name", "Date", "Category", "Description", "Status"];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    name: "",
    eventDate: "",
    category: "",
    status: "",
  });
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchEvent = async (page = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const event = await adminRequest.post(`${BASE_URL}/event/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = event?.data.data.records.map((event) => ({
        displayData: [
          event.name,
          event.eventDateInBs,
          event.eventCategory.name,
          truncateContents(event.description, 10),
          event.status.name,
        ],
        code: event.code,
        status: event.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(event.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/eventCategory/get`);
      setCategory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/status`);
      setStatus(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredEvent = async (page = 1, pageSize = 10) => {
    try {
      const filterParams = {};

      if (filterValues.name) filterParams.name = filterValues.name;
      if (filterValues.category)
        filterParams.eventCategory = filterValues.category;
      if (filterValues.status) filterParams.status = filterValues.status;
      if (filterValues.eventDate)
        filterParams.eventDate = filterValues.eventDate;

      const event = await adminRequest.post(`${BASE_URL}/event/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
        param: filterParams,
      });
      const fetchedRows = event?.data.data.records.map((event) => ({
        displayData: [
          event.name,
          event.eventDateInBs,
          event.eventCategory.name,
          truncateContents(event.description, 10),
          event.status.name,
        ],
        code: event.code,
        status: event.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(event.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvent(currentPage, itemsPerPage);
    fetchStatus();
    fetchCategories();
  }, [currentPage, itemsPerPage]);

  updateAuthToken();
  const handleDelete = async (code) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const response = await adminRequest.post(`${BASE_URL}/event/delete`, {
          code: code,
        });
        if (response.data.code == 0) {
          toast.success("Event deleted successfully");
          fetchEvent();
        } else {
          toast.error("Failed to delete event");
        }
      } catch (error) {
        toast.error("Failed to delete event");
      }
    }
  };

  const handleBlock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to block this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
      html: '<textarea id="blockReason" class="swal2-textarea" placeholder="Enter reason for blocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const blockReason = Swal.getPopup().querySelector("#blockReason").value;
        if (!blockReason) {
          Swal.showValidationMessage("Please enter a reason for block");
        }
        return { blockReason: blockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/event/block`, {
            code: code,
            remarks: result.value.blockReason,
          });
          if (response.data.code == 0) {
            Swal.fire({
              title: "Blocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchEvent();
        } catch (error) {
          toast.error(error.message || "Failed to block");
        }
      }
    });
  };
  const handleUnblock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to unblock this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
      html: '<textarea id="unblockReason" class="swal2-textarea" placeholder="Enter reason for unblocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const unblockReason =
          Swal.getPopup().querySelector("#unblockReason").value;
        if (!unblockReason) {
          Swal.showValidationMessage("Please enter a reason for unblock");
        }
        return { unblockReason: unblockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/event/unblock`,
            {
              code: code,
              remarks: result.value.unblockReason,
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Unblocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchEvent();
        } catch (error) {
          console.log(error.message || "Failed to unblock");
        }
      }
    });
  };
  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    { link: `edit/${row.code}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.code);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.code);
          },
        },
    {
      link: "#",
      text: "Delete",
      onClick: (e) => {
        e.preventDefault();
        handleDelete(row.code);
      },
    },
  ];
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredEvent(currentPage, itemsPerPage);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (value === "Select Status" || value === "Select Event Category") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (name === "eventDate" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const filterFields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: filterValues.name,
      onChange: handleFilterChange,
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      value: filterValues.category,
      onChange: handleFilterChange,
      options: [
        { label: "Select Event Category" },
        ...category.map((category) => ({
          label: category.name,
          value: category.name,
        })),
      ],
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      value: filterValues.status,
      onChange: handleFilterChange,
      options: [
        { label: "Select Status" },
        ...status.map((status) => ({
          label: status.name,
          value: status.name,
        })),
      ],
    },
    {
      name: "eventDate",
      label: "Event Date",
      type: "date",
      value: filterValues.eventDate,
      onChange: handleFilterChange,
    },
  ];

  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchEvent();
    setFilterValues({
      name: "",
      category: "",
      status: "",
      eventDate: "",
    });
  };

  return (
    <div className="eventListListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Event Lists"
            createButtonLabel="Create Event"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={true}
            filterFields={filterFields}
            onFilterSubmit={handleFilterSubmit}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
            resetFilterForm={resetFilterForm}
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EventList;
