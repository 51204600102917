import NepaliDate from "nepali-date-converter";

const convertNepaliDateToAD = (YY, MM, DD) => {
  const adjustedMM = MM - 1;

  const englishDate = new NepaliDate(YY, adjustedMM, DD).toJsDate();

  const year = englishDate.getFullYear().toString();
  const month = String(englishDate.getMonth() + 1).padStart(2, "0");
  const day = String(englishDate.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export default convertNepaliDateToAD;
