import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import Swal from "sweetalert2";
import Pagination from "../../../components/pagination/Pagination";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
const AdminList = () => {
  const headers = ["Name", "Email", "Mobile", "Access Group", "Status"];

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [filterValues, setFilterValues] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    accessGroup: "",
    status: "",
  });
  const [accessGroups, setAccessGroups] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchAdmin = async (page = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const admin = await adminRequest.post(`${BASE_URL}/admin`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = admin.data.data.records.map((a) => [
        a.name,
        a.email,
        a.mobileNumber,
        a.accessGroup.name,
        a.status.name,
      ]);
      setTotalItems(admin.data.data.total);
      setRows(fetchedRows);
    } catch (error) {
      <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFilteredAdmin = async (page = 1, pageSize = 10) => {
    try {
      const filterParams = {};

      if (filterValues.name) filterParams.name = filterValues.name;
      if (filterValues.email) filterParams.email = filterValues.email;
      if (filterValues.mobileNumber)
        filterParams.mobileNumber = filterValues.mobileNumber;

      if (filterValues.status) filterParams.status = filterValues.status;
      if (filterValues.accessGroup)
        filterParams.accessGroup = filterValues.accessGroup;

      const admin = await adminRequest.post(`${BASE_URL}/admin`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
        param: filterParams,
      });
      const fetchedRows = admin.data.data.records.map((a) => [
        a.name,
        a.email,
        a.mobileNumber,
        a.accessGroup.name,
        a.status.name,
      ]);
      setRows(fetchedRows);
    } catch (error) {
      toast.error("Failed to fetch filtered admin list");
    }
  };

  const fetchAccessGroups = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/accessGroup`, {
        firstRow: 0,
        pageSize: 10,
      });
      setAccessGroups(response.data.data.records);
      updateAuthToken();
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStatus = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/status`);
      setStatus(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchAdmin(currentPage, itemsPerPage);
      fetchAccessGroups(currentPage, itemsPerPage);
      fetchStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [currentPage, itemsPerPage]);

  updateAuthToken();

  const getMenuItems = (row) => [
    { link: `view/${row[1]}`, text: "View" },
    { link: `edit/${row[1]}`, text: "Edit" },
  ];

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredAdmin();
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (name === "email" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (name === "mobileNumber" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (value === "Select Access Group" || value === "Select Status") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };
  const filterFields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: filterValues.name,
      onChange: handleFilterChange,
    },
    {
      name: "email",
      label: "Email",
      type: "text",
      value: filterValues.email,
      onChange: handleFilterChange,
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      type: "text",
      value: filterValues.mobileNumber,
      onChange: handleFilterChange,
    },
    {
      name: "accessGroup",
      label: "Access Group",
      type: "select",
      value: filterValues.accessGroup,
      onChange: handleFilterChange,
      options: [
        { label: "Select Access Group", value: "" },
        ...accessGroups.map((group) => ({
          label: group.name,
          value: group.name,
        })),
      ],
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      value: filterValues.status,
      onChange: handleFilterChange,
      options: [
        { label: "Select Status" },
        ...status.map((status) => ({
          label: status.name,
          value: status.name,
        })),
      ],
    },
  ];
  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchAdmin();
    setFilterValues({
      name: "",
      email: "",
      mobileNumber: "",
    });
  };
  return (
    <div className="adminListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Admin List"
            createButtonLabel="Create Admin"
            headers={headers}
            rows={rows}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={true}
            getMenuItems={getMenuItems}
            filterFields={filterFields}
            onFilterSubmit={handleFilterSubmit}
            resetFilterForm={resetFilterForm}
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}

      <ToastContainer position="top-center" />
    </div>
  );
};

export default AdminList;
