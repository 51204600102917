import React, { useEffect, useState } from "react";
import "./Services.scss";
import Card from "../../components/Card/Card";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../utils/requestMethod";
import Loader from "../../components/Loader/Loader";
import NotFound from "../../components/NotFound/NotFound";
import { BASE_URL } from "../../utils/config";
import { TbMapPin2 } from "react-icons/tb";
import { GiPoliceCar } from "react-icons/gi";
import { IoSchoolSharp } from "react-icons/io5";
import { MdEventAvailable, MdShareLocation } from "react-icons/md";
import { BiHealth } from "react-icons/bi";
import { FaRegNewspaper } from "react-icons/fa";
import { TbDeviceLandlinePhone } from "react-icons/tb";
import { BsBank } from "react-icons/bs";
import { AiOutlineNotification } from "react-icons/ai";
import { GoLaw } from "react-icons/go";
import { FaArrowLeftLong } from "react-icons/fa6";
import { BsFillPersonLinesFill } from "react-icons/bs";
const iconMapping = {
  TbMapPin2,
  GiPoliceCar,
  IoSchoolSharp,
  MdEventAvailable,
  BiHealth,
  FaRegNewspaper,
  TbDeviceLandlinePhone,
  BsBank,
  AiOutlineNotification,
  GoLaw,
  MdShareLocation,
  BsFillPersonLinesFill,
};
const Services = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;

    const fetchServices = async () => {
      setIsLoading(true);
      try {
        const response = await adminRequest.get(`${BASE_URL}/navigation`);
        if (isMounted) {
          const navigationData = response.data.data;

          // Find the services group
          const servicesGroup = navigationData.find(
            (group) => group.uiGroupName === "Services"
          );

          if (servicesGroup) {
            // Use a Set to filter out duplicate roles
            const uniqueRoles = Array.from(
              new Set(servicesGroup.roles.map((role) => role.name))
            ).map((name) =>
              servicesGroup.roles.find((role) => role.name === name)
            );
            setServices(uniqueRoles);
          }
        }
      } catch (error) {
        if (isMounted) {
          return <NotFound />;
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchServices();

    return () => {
      isMounted = false;
    };
  }, []);
  updateAuthToken();

  const handleBackClick = () => {
    navigate(-1);
  };

  if (!services) return <Loader />;

  const sortedData = services.sort((a, b) => a.position - b.position);
  return (
    <div className="servicePageContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="servicePageContents">
          <div className="headerTitle">
            <FaArrowLeftLong className="backIcon" onClick={handleBackClick} />
            <span>Services</span>
          </div>
          <div className="serviceCard">
            {sortedData.map((item, index) => {
              const IconComponent = iconMapping[item.icon];
              return (
                <Link
                  to={`${item.navigation}`}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <Card
                    icon={IconComponent ? <IconComponent /> : null}
                    title={item.name}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default Services;
