import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { IoPeopleOutline } from "react-icons/io5";
import { LiaUserSolid } from "react-icons/lia";
import { MdOutlineHomeWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import { adminRequest } from "../../utils/requestMethod";
import { BASE_URL } from "../../utils/config";
import activitiesData from "./activitiesData";
import { trimDate, trimDateTime } from "../../utils/dateUtil";
import { truncateContents } from "../../utils/truncateContents";
import { BiHappyAlt } from "react-icons/bi";
import DateTime from "../../components/DateTime/DateTime";
import { IoChevronUpOutline } from "react-icons/io5";
import { IoChevronDownOutline } from "react-icons/io5";
import parse from "html-react-parser";
import useFetch from "../../hooks/useFetch";
const Dashboard = () => {
  const [userCount, setUserCount] = useState([]);
  const [users, setUsers] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [showAlert, setShowAlert] = useState(true);
  const [eventData, setEventData] = useState([]);
  const [censusData, setCensusData] = useState([]);
  const [censusApi, setCensusApi] = useState([]);
  const [localLevel, setLocalLevel] = useState([]);

  const fetchLocalLevelData = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/localLevelConfig/get`
      );
      setLocalLevel(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCensusApi = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/localLevel/census/get`
      );
      setCensusApi(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCensusData = async () => {
    if (censusApi) {
      try {
        const response = await adminRequest.get(censusApi);
        setCensusData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchNewsData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/news/get`, {
        firstRow: 0,
        pageSize: 5,
      });
      setNewsData(response.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEventData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/event/get`, {
        firstRow: 0,
        pageSize: 4,
      });
      setEventData(response.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await adminRequest.post(`${BASE_URL}/customer/get`, {
        firstRow: 0,
        pageSize: 4,
      });
      setUserCount(response.data.data.total);
      setUsers(response.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchUserData();
    fetchNewsData();
    fetchEventData();
    fetchCensusApi();
    fetchLocalLevelData();
  }, []);
  useEffect(() => {
    fetchCensusData();
  }, [censusApi]);
  const toggleAlert = (e) => {
    e.preventDefault();
    setShowAlert(!showAlert);
  };
  const { data } = useFetch(`${BASE_URL}/admin/viewProfile`, adminRequest);
  return (
    <div className="dashboardContainer">
      <div className="dashboardContents">
        <div className="dashboardBody">
          <div className="alertContainer">
            {showAlert ? (
              <IoChevronUpOutline
                className="closeIcon"
                onClick={toggleAlert}
                title="Hide alert"
              />
            ) : (
              <IoChevronDownOutline
                className="closeIcon"
                onClick={toggleAlert}
                title="Show alert"
              />
            )}
            <span className="alertHeader">
              <BiHappyAlt />
              Namaste, {data && data.data ? data.data.name : ""}
            </span>
            {showAlert && (
              <span className="alertContents">
                Welcome to the ePalika Admin Panel of{" "}
                {localLevel && localLevel.name}
              </span>
            )}
          </div>

          <DateTime />
          <div className="topCards">
            <div className="cardContents">
              <div className="cardContentLeft">
                <IoPeopleOutline className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Total Citizens</span>
                <span className="cardContentRightContent">
                  {censusData.total}
                </span>
              </div>
            </div>
            <div className="cardContents">
              <div className="cardContentLeft">
                <LiaUserSolid className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Total Users</span>
                <span className="cardContentRightContent">{userCount}</span>
              </div>
            </div>
            <div className="cardContents">
              <div className="cardContentLeft">
                <MdOutlineHomeWork className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Total Household</span>
                <span className="cardContentRightContent">
                  {censusData.hh_total}
                </span>
              </div>
            </div>
            <div className="cardContents">
              <div className="cardContentLeft">
                <GiGraduateCap className="cardContentLeftIcon" />
              </div>
              <div className="cardContentRight">
                <span className="cardContentRightHeader">Literacy Rate</span>
                <span className="cardContentRightContent">
                  {censusData.literacy_total}%
                </span>
              </div>
            </div>
          </div>
          <div className="activitiesAndEventContainer">
            <div className="recentActivitiesContainer">
              <div className="recentActivitiesHeader">New Users</div>
              <div className="recentActivitiesBody">
                {users.map((user, index) => (
                  <div className="bodyContents" key={index}>
                    <span className="recentActivitiesBodyLeft">
                      {user.fullName}
                    </span>
                    <span className="recentActivitiesBodyRight">
                      {trimDateTime(user.registeredDate)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="upcomingEventsContainer">
              <div className="upcomingEventsHeader">Upcoming Events</div>
              <div className="upcomingEventsBody">
                {eventData.map((item, index) => (
                  <div className="bodyContents" key={index}>
                    <span className="upcomingEventsBodyLeft">{item.name}</span>
                    <span className="upcomingEventsBodyRight">
                      {item.eventDateInBs}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="newsContainer">
            <div className="newsContainerHeading">Latest News</div>
            {newsData &&
              newsData.map((news, index) => (
                <div className="newsBody" key={index}>
                  <span className="newsTitle">{news.title}</span>
                  <span className="newsContent">
                    {parse(truncateContents(news.content, 30))}
                  </span>
                  <span className="newsPublishedDate">
                    {trimDate(news.recordedDate)}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
