import React, { useState } from "react";
import "./SetEmail.scss";
import logo from "../../assets/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import axios from "axios";

const SetEmail = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const activeUuid = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        axios.post(`${BASE_URL}/admin/setEmail`, {
          uuid: activeUuid,
          password: password,
        }),
        {
          pending: "Processing your request",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate("/login"),
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="createPasswordContainer">
      <div className="createPasswordContents">
        <div className="left">
          <div className="createPasswordlogoContainer">
            <img src={logo} alt="logo" className="logoContainerImg" />
          </div>
          <div className="headerText">Email Change Request</div>
          <p className="descText">
            To confirm your change email request please enter your password.
          </p>
          <form className="passwordContainer" onSubmit={handleSubmit}>
            <span className="passwordInput">
              <label htmlFor="password" className="passwordLabel">
                Password
              </label>
              <div className="passwordWrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  id="password"
                  className="password-box"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="passwordToggleBtn"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                </button>
              </div>
            </span>
            <span className="submit-btn">
              <input type="submit" value="Confirm" className="submitBtn" />
            </span>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default SetEmail;
