import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader/Loader";
import List from "../../components/List/List";
import { toast, ToastContainer } from "react-toastify";
import { adminRequest, updateAuthToken } from "../../utils/requestMethod";
import "react-toastify/dist/ReactToastify.css";

import { BASE_URL } from "../../utils/config";
import { trimDate } from "../../utils/dateUtil";
import NotFound from "../../components/NotFound/NotFound";

const ActivityList = () => {
  const headers = ["Date", , "Object", "Identifier", "Action"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/adminActivityLog/get`
      );
      const fetchedRows = response?.data.data.map((r) => [
        trimDate(r.activityDate),
        r.object,
        r.objectIdentifier,
        r.action,
      ]);
      setRows(fetchedRows);
    } catch (error) {
      <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  updateAuthToken();

  const getMenuItems = (row) => [];

  return (
    <div className="listContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <List
          title="Activity Log"
          showCreateButtonLabel={false}
          createButtonLabel="Create"
          headers={headers}
          rows={rows}
          link="create"
          showEyeViewIcon={false}
          showFilterIcon={false}
          getMenuItems={getMenuItems}
        />
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ActivityList;
