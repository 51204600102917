import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest } from "../../../utils/requestMethod";
import CustomForm from "../../../components/CustomForm/CustomForm";
const CreateWard = () => {
  const initialFormData = {
    wardNumber: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/wardNumbers/create`, {
          wardNumber: formData.wardNumber,
        }),
        {
          pending: "Creating ward",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      toast.error("Failed to create ward at the moment");
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "wardNumber",
      label: "Ward Number",
      type: "text",
      value: formData.wardNumber,
      onChange: handleChange,
    },
  ];
  return (
    <div>
      <CustomForm
        header="Create Ward Number"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateWard;
