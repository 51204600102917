import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import "./NewsList.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import NotFound from "../../../components/NotFound/NotFound";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { trimDate } from ".././../../utils/dateUtil";
import Swal from "sweetalert2";
import Pagination from "../../../components/pagination/Pagination";

const NewsList = () => {
  const headers = ["Title", "Category", "Author", "Published on", "Status"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    title: "",
    recordedDate: "",
    category: "",
    status: "",
  });
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchNews = async (page = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const newsResponse = await adminRequest.post(`${BASE_URL}/news/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = newsResponse.data.data.records.map((news) => ({
        displayData: [
          news.title,
          news.newsCategory.name,
          news.author,
          trimDate(news.recordedDate),
          news.status.name,
        ],
        code: news.code,
        status: news.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(newsResponse.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/newsCategory/get`);
      setCategory(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/status`);
      setStatus(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredNews = async (page = 1, pageSize = 10) => {
    try {
      const filterParams = {};

      if (filterValues.title) filterParams.title = filterValues.title;
      if (filterValues.category)
        filterParams.newsCategory = filterValues.category;
      if (filterValues.status) filterParams.status = filterValues.status;
      if (filterValues.recordedDate)
        filterParams.recordedDate = filterValues.recordedDate;

      const newsResponse = await adminRequest.post(`${BASE_URL}/news/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
        param: filterParams,
      });
      const fetchedRows = newsResponse.data.data.records.map((news) => ({
        displayData: [
          news.title,
          news.newsCategory.name,
          news.author,
          trimDate(news.recordedDate),
          news.status.name,
        ],
        code: news.code,
        status: news.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(newsResponse.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNews(currentPage, itemsPerPage);
    fetchStatus();
    fetchCategories();
  }, [currentPage, itemsPerPage]);

  const handleDelete = async (code) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this news item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const response = await adminRequest.post(`${BASE_URL}/news/delete`, {
          code: code,
        });
        if (response.data.code == 0) {
          toast.success(response.data.message);
          fetchNews();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Failed to delete news item");
      }
    }
  };

  const handleBlock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to block this news?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
      html: '<textarea id="blockReason" class="swal2-textarea" placeholder="Enter reason for blocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const blockReason = Swal.getPopup().querySelector("#blockReason").value;
        if (!blockReason) {
          Swal.showValidationMessage("Please enter a reason for block");
        }
        return { blockReason: blockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/news/block`, {
            code: code,
            remarks: result.value.blockReason,
          });
          if (response.data.code == 0) {
            Swal.fire({
              title: "Blocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchNews();
        } catch (error) {
          toast.error(error.message || "Failed to block");
        }
      }
    });
  };
  const handleUnblock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to unblock this news?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
      html: '<textarea id="unblockReason" class="swal2-textarea" placeholder="Enter reason for unblocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const unblockReason =
          Swal.getPopup().querySelector("#unblockReason").value;
        if (!unblockReason) {
          Swal.showValidationMessage("Please enter a reason for unblock");
        }
        return { unblockReason: unblockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(`${BASE_URL}/news/unblock`, {
            code: code,
            remarks: result.value.unblockReason,
          });
          if (response.data.code == 0) {
            Swal.fire({
              title: "Unblocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchNews();
        } catch (error) {
          console.log(error.message || "Failed to unblock");
        }
      }
    });
  };
  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    { link: `edit/${row.code}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.code);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.code);
          },
        },
    {
      link: "#",
      text: "Delete",
      onClick: (e) => {
        e.preventDefault();
        handleDelete(row.code);
      },
    },
  ];
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredNews(currentPage, itemsPerPage);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "title" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (value === "Select Status" || value === "Select News Category") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (name === "recordedDate" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const filterFields = [
    {
      name: "title",
      label: "Title",
      type: "text",
      value: filterValues.title,
      onChange: handleFilterChange,
    },
    {
      name: "category",
      label: "Category",
      type: "select",
      value: filterValues.category,
      onChange: handleFilterChange,
      options: [
        { label: "Select News Category" },
        ...category.map((category) => ({
          label: category.name,
          value: category.name,
        })),
      ],
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      value: filterValues.status,
      onChange: handleFilterChange,
      options: [
        { label: "Select Status" },
        ...status.map((status) => ({
          label: status.name,
          value: status.name,
        })),
      ],
    },
    {
      name: "recordedDate",
      label: "Published Date",
      type: "date",
      value: filterValues.recordedDate,
      onChange: handleFilterChange,
    },
  ];

  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchNews();
    setFilterValues({
      heading: "",
      category: "",
      status: "",
      recordedDate: "",
    });
  };
  return (
    <div className="newsListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="News Lists"
            createButtonLabel="Create News"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showFilterIcon={true}
            filterFields={filterFields}
            onFilterSubmit={handleFilterSubmit}
            showEyeViewIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
            resetFilterForm={resetFilterForm}
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default NewsList;
