import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import NotFound from "../../../components/NotFound/NotFound";
import Pagination from "../../../components/pagination/Pagination";
const EducationList = () => {
  const headers = [
    "Name",
    "Address",
    "Phone",
    "Email",
    "Level",
    "Ownership",
    "Status",
  ];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filterValues, setFilterValues] = useState({
    name: "",
    ownership: "",
    level: "",
    status: "",
  });
  const [ownership, setOwnership] = useState([]);
  const [level, setLevel] = useState([]);
  const [status, setStatus] = useState([]);

  const fetchData = async (page = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const data = await adminRequest.post(`${BASE_URL}/education`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = data.data.data.records.map((e) => ({
        displayData: [
          e.name,
          e.address,
          e.phone,
          e.email,
          e.educationType.type,
          e.educationOwnedBy.ownedBy,
          e.status.name,
        ],
        code: e.code,
        status: e.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(data.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOwnership = async () => {
    try {
      const response = await adminRequest.get(
        `${BASE_URL}/educationOwnedBy/get`
      );
      setOwnership(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchLevel = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/educationType/get`);
      setLevel(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStatus = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/status`);
      setStatus(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFilteredEducation = async (page = 1, pageSize = 10) => {
    try {
      const filterParams = {};

      if (filterValues.name) filterParams.name = filterValues.name;
      if (filterValues.ownership)
        filterParams.educationOwnedBy = filterValues.ownership;
      if (filterValues.status) filterParams.status = filterValues.status;
      if (filterValues.level) filterParams.educationType = filterValues.level;

      const data = await adminRequest.post(`${BASE_URL}/education`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
        param: filterParams,
      });
      const fetchedRows = data.data.data.records.map((e) => ({
        displayData: [
          e.name,
          e.address,
          e.phone,
          e.email,
          e.educationType.type,
          e.educationOwnedBy.ownedBy,
          e.status.name,
        ],
        code: e.code,
        status: e.status.name,
      }));
      setRows(fetchedRows);
      setTotalItems(fetchedRows.data.data.total);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData(currentPage, itemsPerPage);
      fetchLevel();
      fetchOwnership();
      fetchStatus();
    }
    return () => {
      isMounted = false;
    };
  }, [currentPage, itemsPerPage]);

  updateAuthToken();
  const handleDelete = async (code) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this education institution?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/education/delete`,
          {
            code: code,
          }
        );
        if (response.data.code == 0) {
          toast.success("Education institution deleted successfully");
          fetchData(currentPage, itemsPerPage);
        } else {
          toast.error("Failed to delete education institution");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleBlock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to block this education institition?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
      html: '<textarea id="blockReason" class="swal2-textarea" placeholder="Enter reason for blocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const blockReason = Swal.getPopup().querySelector("#blockReason").value;
        if (!blockReason) {
          Swal.showValidationMessage("Please enter a reason for block");
        }
        return { blockReason: blockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/education/block`,
            {
              code: code,
              remarks: result.value.blockReason,
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Blocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchData(currentPage, itemsPerPage);
        } catch (error) {
          toast.error(error.message || "Failed to block");
        }
      }
    });
  };
  const handleUnblock = async (code) => {
    Swal.fire({
      title: "Are you sure you want to unblock this education institution?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
      html: '<textarea id="unblockReason" class="swal2-textarea" placeholder="Enter reason for unblocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const unblockReason =
          Swal.getPopup().querySelector("#unblockReason").value;
        if (!unblockReason) {
          Swal.showValidationMessage("Please enter a reason for unblock");
        }
        return { unblockReason: unblockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/education/unblock`,
            {
              code: code,
              remarks: result.value.unblockReason,
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Unblocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchData(currentPage, itemsPerPage);
        } catch (error) {
          console.log(error.message || "Failed to unblock");
        }
      }
    });
  };
  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    { link: `edit/${row.code}`, text: "Edit" },
    row.status !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => {
            e.preventDefault();
            handleBlock(row.code);
          },
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => {
            e.preventDefault();
            handleUnblock(row.code);
          },
        },
    {
      link: "#",
      text: "Delete",
      onClick: (e) => {
        e.preventDefault();
        handleDelete(row.code);
      },
    },
  ];
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchFilteredEducation(currentPage, itemsPerPage);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && value === "") {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else if (
      value === "Select Status" ||
      value === "Select Education Level" ||
      value === "Select Education Ownership"
    ) {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    } else {
      setFilterValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const filterFields = [
    {
      name: "name",
      label: "Name",
      type: "text",
      value: filterValues.name,
      onChange: handleFilterChange,
    },
    {
      name: "ownership",
      label: "Ownership",
      type: "select",
      value: filterValues.ownership,
      onChange: handleFilterChange,
      options: [
        { label: "Select Education Ownership" },
        ...ownership.map((ownership) => ({
          label: ownership.ownedBy,
          value: ownership.ownedBy,
        })),
      ],
    },
    {
      name: "level",
      label: "Level",
      type: "select",
      value: filterValues.level,
      onChange: handleFilterChange,
      options: [
        { label: "Select Education Level" },
        ...level.map((level) => ({
          label: level.type,
          value: level.type,
        })),
      ],
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      value: filterValues.status,
      onChange: handleFilterChange,
      options: [
        { label: "Select Status" },
        ...status.map((status) => ({
          label: status.name,
          value: status.name,
        })),
      ],
    },
  ];

  const resetFilterForm = (e) => {
    e.preventDefault();
    fetchData();
    setFilterValues({
      name: "",
      level: "",
      status: "",
      ownership: "",
    });
  };
  return (
    <div className="educationListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="Educational Institution Lists"
            createButtonLabel="Create Education"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showFilterIcon={true}
            filterFields={filterFields}
            onFilterSubmit={handleFilterSubmit}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
            resetFilterForm={resetFilterForm}
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EducationList;
