import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginAdmin } from "../../services/loginService";
import { performLogin } from "../../auth";
import { MdOutlineEmail, MdOutlinePassword } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Login.scss";
import thumbnail from "../../assets/coatofarm.png";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import { BASE_URL, IMG_URL } from "../../utils/config";
import { publicRequest } from "../../utils/requestMethod";
const Login = () => {
  const navigate = useNavigate();
  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [localLevel, setLocalLevel] = useState([]);

  const fetchLocalLevelData = async () => {
    try {
      const response = await publicRequest.get(
        `${BASE_URL}/localLevelConfig/get`
      );
      setLocalLevel(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchLocalLevelData();
  }, []);
  const handleChange = (event, field) => {
    const changedValue = event.target.value;
    setLoginDetail({
      ...loginDetail,
      [field]: changedValue,
    });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      await toast.promise(loginAdmin(loginDetail), {
        pending: "Logging in...",
        success: {
          render({ data }) {
            if (data.code == 0) {
              performLogin(data, () => {
                // Redirect to dashboard
                navigate("/");
              });
              return data.message;
            } else {
              throw new Error(data.message);
            }
          },
        },
        error: {
          render({ data }) {
            return data.message || "An error occurred";
          },
        },
      });
    } catch (error) {
      console.error(error);
      toast.error("Username or password doesnot match");
    }
  };

  const isFormValid = loginDetail.username && loginDetail.password;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="loginContainer">
      <div className="loginContents">
        <div className="loginContentsLeft">
          <img
            // src={`${IMG_URL}${localLevel.image}`}
            src={thumbnail}
            className="loginImg"
            alt="local_level_logo"
          />
        </div>
        <div className="loginContentsRight">
          <div className="logoContainer">
            <img src={logo} alt="logo" className="companyLogo" />
          </div>
          <div className="headerContainer">
            <span className="header">Welcome Back!</span>
            <span className="desc">{localLevel && localLevel.name}</span>
          </div>
          <form className="loginForm" onSubmit={handleFormSubmit}>
            <div className="inputField">
              <MdOutlineEmail className="inputFieldIcon" />
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Enter your username"
                className="emailField"
                value={loginDetail.username}
                onChange={(e) => handleChange(e, "username")}
              />
            </div>
            <div className="inputField">
              <MdOutlinePassword className="inputFieldIcon" />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                className="passwordField"
                value={loginDetail.password}
                onChange={(e) => handleChange(e, "password")}
              />
              <span
                className="passwordToggleIcon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
            <div className="loginButton">
              <button
                type="submit"
                className="submitButton"
                onClick={handleFormSubmit}
                disabled={!isFormValid}
              >
                Login
              </button>
            </div>
          </form>
          <span className="versionInfo" title="Release: 2024/11/07">
            ePalika v0.1.3
          </span>
        </div>
      </div>
      <ToastContainer position="top-center" reverseOrder={true} />
    </div>
  );
};

export default Login;
