import React, { useEffect, useState } from "react";
import List from "../../../../components/List/List";
import "./NewsCategoryList.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../../utils/requestMethod";
import { BASE_URL } from "../../../../utils/config";
import Loader from "../../../../components/Loader/Loader";
import Swal from "sweetalert2";

const NewsCategoryList = () => {
  const headers = ["Name", "Status"];
  const [rows, setRows] = useState([]);

  const fetchNewsCategories = async () => {
    try {
      const categories = await adminRequest.get(`${BASE_URL}/newsCategory/get`);
      const fetchedRows = categories?.data.data.map((news) => [
        news.name,
        news.status.name,
      ]);
      console.log(categories?.data.message);
      setRows(fetchedRows);
    } catch (error) {
      toast.error("Failed to fetch news categories");
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchNewsCategories();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  updateAuthToken();

  const getMenuItems = (row) => [
    row[1] !== "BLOCKED"
      ? {
          link: "#",
          text: "Block",
          onClick: (e) => handleBlockCategory(e, row[0]),
        }
      : {
          link: "#",
          text: "Unblock",
          onClick: (e) => handleUnblockCategory(e, row[0]),
        },
  ];
  const handleBlockCategory = async (e, name) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to block this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Block",
      html: '<textarea id="blockReason" class="swal2-textarea" placeholder="Enter reason for blocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const blockReason = Swal.getPopup().querySelector("#blockReason").value;
        if (!blockReason) {
          Swal.showValidationMessage("Please enter a reason for block");
        }
        return { blockReason: blockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/newsCategory/block`,
            {
              name: name,
              remarks: result.value.blockReason,
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Blocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchNewsCategories();
        } catch (error) {
          toast.error(error.message || "Failed to block");
        }
      }
    });
  };
  const handleUnblockCategory = async (e, name) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure you want to unblock this category?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00425A",
      cancelButtonColor: "#FC0000",
      confirmButtonText: "Unblock",
      html: '<textarea id="unblockReason" class="swal2-textarea" placeholder="Enter reason for unblocking" rows="3" style="width: 80%;"></textarea>',
      preConfirm: () => {
        const unblockReason =
          Swal.getPopup().querySelector("#unblockReason").value;
        if (!unblockReason) {
          Swal.showValidationMessage("Please enter a reason for unblock");
        }
        return { unblockReason: unblockReason };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await adminRequest.post(
            `${BASE_URL}/newsCategory/unblock`,
            {
              name: name,
              remarks: result.value.unblockReason,
            }
          );
          if (response.data.code == 0) {
            Swal.fire({
              title: "Unblocked!",
              text: `${response.data.message}`,
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: `${response.data.message}`,
              icon: "error",
            });
          }
          fetchNewsCategories();
        } catch (error) {
          console.log(error.message || "Failed to unblock");
        }
      }
    });
  };
  return (
    <div className="newsCategoryListContainer">
      {rows.length > 0 ? (
        <List
          title="News Category Lists"
          createButtonLabel="Create News Category"
          headers={headers}
          rows={rows}
          link="create"
          showEyeViewIcon={false}
          showFilterIcon={false}
          getMenuItems={getMenuItems}
        />
      ) : (
        <Loader />
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default NewsCategoryList;
