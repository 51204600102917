import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";

const CreateNotice = () => {
  const initialFormData = {
    subject: "",
    image: "",
    releasedDate: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.image);

    formDataToSend.append(
      "notice",
      new Blob(
        [
          JSON.stringify({
            subject: formData.subject,
            releasedDate: formData.releasedDate,
          }),
        ],
        { type: "application/json" }
      )
    );

    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/notice/create`, formDataToSend),
        {
          pending: "Posting Notice",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      } else {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  updateAuthToken();

  const fields = [
    {
      name: "subject",
      label: "Subject",
      type: "text",
      value: formData.subject,
      onChange: handleChange,
    },
    {
      name: "releasedDate",
      label: "Effective Date",
      type: "date",
      value: formData.releasedDate,
      onChange: handleChange,
    },
    {
      name: "image",
      label: "File",
      type: "file",
      onChange: handleImageChange,
    },
  ];

  return (
    <div>
      <CustomForm
        header="Create Notice"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateNotice;
