import React, { useEffect, useState } from "react";
import NotFound from "../../../components/NotFound/NotFound";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import List from "../../../components/List/List";
import Loader from "../../../components/Loader/Loader";
import { BASE_URL } from "../../../utils/config";
export const WardList = () => {
  const headers = ["Ward Number", "Description"];
  const [rows, setRows] = useState([]);
  const fetchWards = async () => {
    try {
      const wards = await adminRequest.get(`${BASE_URL}/wardNumbers/get`);
      const fetchedRows = wards.data.data.map((c) => [
        c.wardNumber,
        "Not available at the moment",
      ]);
      setRows(fetchedRows);
    } catch (error) {
      <NotFound />;
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchWards();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  updateAuthToken();
  const getMenuItems = (row) => [
    {
      link: "#",
      text: "Edit",
    },
  ];
  return (
    <div className="wardListContainer">
      {rows.length > 0 ? (
        <List
          title="Municipal Ward Configuration"
          createButtonLabel="Create Ward"
          headers={headers}
          rows={rows}
          link="create"
          showEyeViewIcon={false}
          showFilterIcon={false}
          getMenuItems={getMenuItems}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};
