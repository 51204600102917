import React, { useEffect, useState } from "react";
import "./EmailTemplateList.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../../components/Card/Card";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { MdOutlineAttachEmail } from "react-icons/md";

const EmailTemplateList = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  const [data, setData] = useState([]);
  const fetchData = async () => {
    try {
      const response = await adminRequest.get(`${BASE_URL}/emailTemplate/get`);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData();
    return () => {
      isMounted = false;
    };
  }, []);
  updateAuthToken();
  return (
    <div className="emailTemplateListContainer">
      <div className="emailTemplateContent">
        <div className="emailTemplateHeader">
          <span className="backIcon" onClick={handleBackClick}>
            <FaArrowLeftLong />
          </span>
          <span>Email Template Management</span>
        </div>
        <div className="emailTemplateListCard">
          {data &&
            data.map((template, index) => (
              <Link
                key={index}
                to={`${template.name}`}
                style={{ textDecoration: "none" }}
              >
                <Card
                  icon={<MdOutlineAttachEmail />}
                  title={`${template.name}`}
                />
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateList;
