import React, { useEffect, useState } from "react";
import CustomForm from "../../components/CustomForm/CustomForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../utils/requestMethod";
import { BASE_URL } from "../../utils/config";
import { useNavigate } from "react-router-dom";

const ChangeEmail = () => {
  const initialFormData = {
    email: "",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [oldEmail, setOldEmail] = useState("");

  useEffect(() => {
    let isMounted = true;
    const viewProfile = async () => {
      try {
        const profileData = await adminRequest.get(
          `${BASE_URL}/admin/viewProfile`
        );
        if (isMounted) {
          setOldEmail(profileData.data.data.email);
          updateAuthToken();
        }
      } catch (error) {
        if (isMounted) {
          console.log("Something went wrong");
        }
      }
    };
    viewProfile();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/admin/sendEmailChangeLink`, {
          email: formData.email,
        }),
        {
          pending: "Processing your request",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      toast.error("Failed to change email");
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "oldemail",
      label: "Old Email",
      type: "email",
      value: oldEmail,
      onChange: handleChange,
      isDisabled: true,
    },
    {
      name: "email",
      label: "New Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
  ];

  return (
    <div className="changeEmailContainer">
      <CustomForm
        header="Change Email"
        fields={fields}
        flexDirection="column"
        createButtonLabel="Change Email"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default ChangeEmail;
