import React from "react";
import "./AppConfiguration.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsMenuApp } from "react-icons/bs";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../../components/Card/Card";

const AppConfiguration = () => {
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  return (
    <div className="appConfigurationContainer">
      <div className="appConfigurationPageContents">
        <div className="appConfigurationHeader">
          <span className="backIcon" onClick={handleBackClick}>
            <FaArrowLeftLong />
          </span>
          <span>App Configuration</span>
        </div>
        <div className="appConfigurationCard">
          <Link to="appMenu" style={{ textDecoration: "none" }}>
            <Card icon={<BsMenuApp />} title="App Menu" />
          </Link>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default AppConfiguration;
