import React, { useEffect, useState } from "react";
import CustomForm from "../../../components/CustomForm/CustomForm";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import NotFound from "../../../components/NotFound/NotFound";
import { trimDate } from "../../../utils/dateUtil";

const EditMunicipalAuthorities = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeURL = location.pathname.split("/")[3];

  const [data, setData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    post: "",
    email: "",
    contact: "",
    tenureStart: "",
    tenureEnd: "",
    image: "",
    ward: "",
    role: "",
  });
  const [wardNo, setWardNo] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const response = await adminRequest.post(
          `${BASE_URL}/municipalInfo/get/detail`,
          {
            code: `${activeURL}`,
          }
        );
        setData(response.data);
        setFormData({
          name: response.data.data.name,
          post: response.data.data.post,
          contact: response.data.data.contact,
          email: response.data.data.email,
          ...(response.data.data.tenureStart && {
            tenureStart: trimDate(response.data.data.tenureStart),
          }),
          ...(response.data.data.tenureEnd && {
            tenureEnd: trimDate(response.data.data.tenureEnd),
          }),
          ...(response.data.data.ward?.wardNumber && {
            ward: response.data.data.ward.wardNumber,
          }),
          image: response.data.data.image,
          role: response.data.data.municipalRole.name,
        });
      } catch (error) {
        <NotFound />;
      }
    };

    const fetchWards = async () => {
      try {
        const wards = await adminRequest.get(`${BASE_URL}/wardNumbers/get`);
        if (isMounted) {
          const sortedWards = wards.data.data.sort(
            (a, b) => parseInt(a.wardNumber) - parseInt(b.wardNumber)
          );
          setWardNo(sortedWards);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch wards at the moment");
        }
      }
    };
    const fetchRoles = async () => {
      try {
        const role = await adminRequest.get(`${BASE_URL}/municipalRole/get`);
        if (isMounted) {
          setRoles(role.data.data);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch roles at the moment");
        }
      }
    };
    fetchData();
    fetchWards();
    fetchRoles();
  }, [activeURL]);

  updateAuthToken();

  if (!data || !data.data) {
    return <Loader />;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/municipalInfo/update`, {
          code: activeURL,
          name: formData.name,
          post: formData.post,
          email: formData.email,
          contact: formData.contact,
          tenureStart: formData.tenureStart,
          tenureEnd: formData.tenureEnd,
          image: formData.image,
          ward: {
            wardNumber: formData.ward,
          },
          municipalRole: {
            name: formData.role,
          },
        }),
        {
          pending: "Your request is being processed",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 1500,
          onClose: () => navigate(-1),
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      <NotFound />;
    }
  };

  const fields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "post",
      label: "Post",
      type: "text",
      value: formData.post,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "contact",
      label: "Contact Number",
      type: "text",
      value: formData.contact,
      onChange: handleChange,
    },
    {
      name: "tenureStart",
      label: "Tenure Start Date",
      type: "date",
      value: formData.tenureStart,
      onChange: handleChange,
    },
    {
      name: "tenureEnd",
      label: "Tenure End Date",
      type: "date",
      value: formData.tenureEnd,
      onChange: handleChange,
    },
    {
      name: "ward",
      label: "Ward No",
      type: "select",
      value: formData.ward,
      onChange: handleChange,
      options: [
        { label: "Select Ward No.", value: "" },
        ...wardNo.map((ward) => ({
          label: ward.wardNumber,
          value: ward.wardNumber,
        })),
      ],
    },
    {
      name: "role",
      label: "Municipal Role",
      type: "select",
      value: formData.role,
      onChange: handleChange,
      options: [
        { label: "Select Role", value: "" },
        ...roles.map((role) => ({
          label: role.name,
          value: role.name,
        })),
      ],
    },
  ];

  return (
    <div className="editContainer">
      <CustomForm
        header="Edit Municipal Authority"
        fields={fields}
        createButtonLabel="Update"
        flexDirection="row"
        onSubmit={handleSubmit}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditMunicipalAuthorities;
