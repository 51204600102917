import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomForm from "../../../components/CustomForm/CustomForm";
import NotFound from "../../../components/NotFound/NotFound";
const CreateMunicipalAuthorities = () => {
  const initialFormData = {
    name: "",
    post: "",
    email: "",
    contact: "",
    tenureStart: "",
    tenureEnd: "",
    ward: "",
    role: "",
    image: null,
  };
  const [wardNo, setWardNo] = useState([]);
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const fetchWards = async () => {
      try {
        const wards = await adminRequest.get(`${BASE_URL}/wardNumbers/get`);
        if (isMounted) {
          const sortedWards = wards.data.data.sort(
            (a, b) => parseInt(a.wardNumber) - parseInt(b.wardNumber)
          );
          setWardNo(sortedWards);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch wards at the moment");
        }
      }
    };
    const fetchRoles = async () => {
      try {
        const role = await adminRequest.get(`${BASE_URL}/municipalRole/get`);
        if (isMounted) {
          setRoles(role.data.data);
        }
      } catch (error) {
        if (isMounted) {
          toast.error("Failed to fetch roles at the moment");
        }
      }
    };
    updateAuthToken();
    fetchWards();
    fetchRoles();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    formDataToSend.append("image", formData.image);

    formDataToSend.append(
      "municipal-info",
      new Blob(
        [
          JSON.stringify({
            name: formData.name,
            post: formData.post,
            email: formData.email,
            contact: formData.contact,
            tenureStart: formData.tenureStart,
            tenureEnd: formData.tenureEnd,
            ward: {
              wardNumber: formData.ward,
            },
            municipalRole: {
              name: formData.role,
            },
          }),
        ],
        { type: "application/json" }
      )
    );
    try {
      const response = await toast.promise(
        adminRequest.post(`${BASE_URL}/municipalInfo/create`, formDataToSend),
        {
          pending: "Processing your request",
        }
      );
      if (response.data.code == 0) {
        toast.success(response.data.message, {
          autoClose: 500,
          onClose: () => navigate(-1),
        });
      }
      if (response.data.code != 0) {
        toast.error(response.data.message);
      }
      setFormData(initialFormData);
    } catch (error) {
      <NotFound />;
    } finally {
      setIsSubmitting(false);
    }
  };
  const fields = [
    {
      name: "name",
      label: "Full Name",
      type: "text",
      value: formData.name,
      onChange: handleChange,
    },
    {
      name: "post",
      label: "Post",
      type: "text",
      value: formData.post,
      onChange: handleChange,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      value: formData.email,
      onChange: handleChange,
    },
    {
      name: "contact",
      label: "Contact Number",
      type: "text",
      value: formData.contact,
      onChange: handleChange,
    },
    {
      name: "tenureStart",
      label: "Tenure Start Date",
      type: "date",
      value: formData.tenureStart,
      onChange: handleChange,
    },
    {
      name: "tenureEnd",
      label: "Tenure End Date",
      type: "date",
      value: formData.tenureEnd,
      onChange: handleChange,
    },
    {
      name: "image",
      label: "Image",
      type: "file",
      value: formData.image,
      onChange: handleImageChange,
    },
    {
      name: "ward",
      label: "Ward No",
      type: "select",
      value: formData.ward,
      onChange: handleChange,
      options: [
        { label: "Select Ward No.", value: "" },
        ...wardNo.map((ward) => ({
          label: ward.wardNumber,
          value: ward.wardNumber,
        })),
      ],
    },
    {
      name: "role",
      label: "Municipal Role",
      type: "select",
      value: formData.role,
      onChange: handleChange,
      options: [
        { label: "Select Role", value: "" },
        ...roles.map((role) => ({
          label: role.name,
          value: role.name,
        })),
      ],
    },
  ];
  return (
    <div className="createForm">
      <CustomForm
        header="Create Municipal Authorities"
        fields={fields}
        flexDirection="row"
        createButtonLabel="Create"
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
      <ToastContainer position="top-center" />
    </div>
  );
};

export default CreateMunicipalAuthorities;
