import React, { useState, useEffect } from "react";
import "./DateTime.scss";
import NepaliDate from "nepali-date-converter";
const DateTime = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
      timeZone: "Asia/Kathmandu",
    });
  };

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "Asia/Kathmandu",
    });
  };

  const dateInBs = new NepaliDate().format("ddd DD, MMMM YYYY", "np");
  return (
    <div className="dateTimeContainer">
      <div className="dateContainer">{dateInBs}</div>
      <div className="timeContainer"> {formatTime(currentTime)}</div>
      <div className="engDateContainer">{formatDate(currentTime)}</div>
    </div>
  );
};

export default DateTime;
