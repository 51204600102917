import React, { useEffect, useState } from "react";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import List from "../../../components/List/List";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loader/Loader";
import NotFound from "../../../components/NotFound/NotFound";

const WardInformationList = () => {
  const headers = ["Ward No.", , "Phone", "Email", "Address"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await adminRequest.get(`${BASE_URL}/wardinfo/get`);
      const fetchedRows = response?.data.data.map((i) => [
        i.ward.wardNumber,
        i.contactNumber,
        i.email,
        i.address,
      ]);
      setRows(fetchedRows);
    } catch (error) {
      <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, []);
  updateAuthToken();

  const getMenuItems = (row) => [{ link: `edit/${row[0]}`, text: "Edit" }];
  return (
    <div className="listContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <List
          title="Ward Information"
          createButtonLabel="Create Ward Info"
          headers={headers}
          rows={rows}
          link="create"
          showEyeViewIcon={false}
          showFilterIcon={false}
          getMenuItems={getMenuItems}
        />
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default WardInformationList;
