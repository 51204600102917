import React, { useEffect, useState } from "react";
import List from "../../../components/List/List";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { adminRequest, updateAuthToken } from "../../../utils/requestMethod";
import { BASE_URL } from "../../../utils/config";
import { trimDate } from "../../../utils/dateUtil";
import NotFound from "../../../components/NotFound/NotFound";
import Loader from "../../../components/Loader/Loader";
import Pagination from "../../../components/pagination/Pagination";
const QueryList = () => {
  const headers = ["User", "Subject", "Mobile No.", "Recorded Date", "Status"];
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = async (page = 1, pageSize = 10) => {
    setIsLoading(true);
    try {
      const list = await adminRequest.post(`${BASE_URL}/queries/get`, {
        firstRow: (page - 1) * pageSize + 0,
        pageSize: pageSize,
      });
      const fetchedRows = list.data.data.records.map((l) => ({
        displayData: [
          l.customer.fullName,
          l.subject,
          l.customer.mobileNumber,
          trimDate(l.recordedDate),
          l.replied ? "Replied" : "Not Replied",
        ],
        code: l.code,
      }));
      setTotalItems(list.data.data.total);
      setRows(fetchedRows);
    } catch (error) {
      return <NotFound />;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchData(currentPage, itemsPerPage);
    return () => {
      isMounted = false;
    };
  }, [currentPage, itemsPerPage]);

  updateAuthToken();

  const getMenuItems = (row) => [
    { link: `view/${row.code}`, text: "View" },
    { link: `reply/${row.code}`, text: "Reply" },
  ];

  return (
    <div className="queryListContainer">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <List
            title="User Queries"
            createButtonLabel="Create Financial Institution"
            headers={headers}
            rows={rows.map((row) => row.displayData)}
            link="create"
            showEyeViewIcon={false}
            showCreateButtonLabel={false}
            showFilterIcon={false}
            getMenuItems={(row) =>
              getMenuItems(rows.find((r) => r.displayData === row))
            }
          />
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            itemsPerPage={itemsPerPage}
            onPageChange={setCurrentPage}
            onItemsPerPageChange={setItemsPerPage}
          />
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
};

export default QueryList;
